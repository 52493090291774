import { tokenHeaders } from "../../services/helpers";
import Axios from "axios";

const ADMIN_URL = process.env.VUE_APP_ADMIN_URL;

const state = {
  contact_us: [],
  contact_current_page: 1,
  contact_last_page: 1,

  faq_items: [],
  faq_current_page: 1,
  faq_last_page: 1,
};

const getters = {
  contact_us_details: (state) => ({
    contact_items: state.contact_us,
    current_page: state.contact_current_page,
    last_page: state.contact_last_page,
  }),
  faq_details: (state) => ({
    faq_items: state.faq_items,
    current_page: state.faq_current_page,
    last_page: state.faq_last_page,
  }),
};

const actions = {
  fetchContactItems: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + `static/site/contact`;

      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_CONTACT_US", { data, last_page, current_page: params.page });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  fetchFaqItems: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + `static/site/faq`;

      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_FAQ_ITEMS", { data, last_page, current_page: params.page });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  createFaqItem: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );

    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "static/site/faq/create";
      const response = await Axios.post(url, form, headers);
      const { message, data } = response.data;

      commit("ADD_FAQ_ITEM", data);
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  updateFaqStatus: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );

    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "static/site/faq/status";
      const response = await Axios.post(url, form, headers);
      const { message, data } = response.data;

      commit("UPDATE_FAQ_ITEM", data);
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
};

const mutations = {
  SET_CONTACT_US: (state, newValue) => {
    state.contact_us = newValue.data;
    state.contact_current_page = newValue.current_page;
    state.contact_last_page = newValue.last_page;
  },
  SET_FAQ_ITEMS: (state, newValue) => {
    state.faq_items = newValue.data;
    state.faq_current_page = newValue.current_page;
    state.faq_last_page = newValue.last_page;
  },
  ADD_FAQ_ITEM: (state, newValue) => {
    state.faq_items = [newValue, ...state.faq_items];
  },
  UPDATE_FAQ_ITEM: (state, newValue) => {
    let newArray = state.faq_items.map((item) => {
      if (item.id == newValue.id) {
        item = newValue;
      }
      return item;
    });

    state.faq_items = newArray;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
