<script>
import appConfig from "@/app.config";
import 'vue-select/dist/vue-select.css';
import Axios from 'axios';
import { mapGetters, mapActions } from "vuex";

export default {
  name: "app",
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    }
  },
  created() {
    fetch('https://api.ipify.org?format=json')
    .then(x => x.json())
    .then(({ ip }) => {
        this.fetchAdmin(ip)
    });
  },
  methods: {
    ...mapActions(["fetchAdmin"]),
  }
};
</script>

<template>
  <div id="app">
    <RouterView />
  </div>
</template>
