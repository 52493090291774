import { tokenHeaders } from "../../services/helpers";
import Axios from "axios";

const ADMIN_URL = process.env.VUE_APP_ADMIN_URL;
const state = {
  loans: [],
  loans_page: 1,
  loans_last_page: 1,

  loan_limit: {},
  group_loan_limit: {},

  loanprofiles: [],
  loanprofiles_page: 1,
  loanprofiles_last_page: 1,

  guarantors: [],
  guarantors_page: 1,
  guarantors_last_page: 1,

  groups_guarantors: [],
  groups_guarantors_page: 1,
  groups_guarantors_last_page: 1,

  loan_guarantors: [],
  loan_guarantors_page: 1,
  loan_guarantors_last_page: 1,

  loan_borrower: {},
  loan_lenders_analytics: {},
  loan_borrowers_analytics: {},

  loan_lenders: [],
  loan_lenders_page: 1,
  loan_lenders_last_page: 1,

  loan_borrowers: [],
  loan_borrowers_page: 1,
  loan_borrowers_last_page: 1,
};

const getters = {
  loan_limit: (state) => ({
    loan_limit: state.loan_limit,
  }),
  group_loan_limit: (state) => ({
    group_loan_limit: state.group_loan_limit,
  }),
  loanprofile_info: (state) => ({
    loanprofiles: state.loanprofiles,
    current_page: state.loanprofiles_page,
    last_page: state.loanprofiles_last_page,
  }),
  loanguarantors_info: (state) => ({
    guarantors: state.guarantors,
    current_page: state.guarantors_page,
    last_page: state.guarantors_last_page,
  }),
  single_loan_guarantors_info: (state) => ({
    guarantors: state.loan_guarantors,
    current_page: state.loan_guarantors_page,
    last_page: state.loan_guarantors_last_page,
  }),
  loan_client_borrowers_info: (state) => ({
    client_borrowers: state.guarantors,
    current_page: state.guarantors_page,
    last_page: state.guarantors_last_page,
  }),
  loan_group_borrowers_info: (state) => ({
    group_borrowers: state.groups_guarantors,
    current_page: state.groups_guarantors_page,
    last_page: state.groups_guarantors_last_page,
  }),
  loan_borrower: (state) => ({
    loan_borrower: state.loan_borrower,
  }),
  loan_lenders_info: (state) => ({
    loan_lenders: state.loan_lenders,
    current_page: state.loan_lenders_page,
    last_page: state.loan_lenders_last_page,
  }),
  loan_lenders_analytics_info: (state) => ({
    loan_lenders_analytics: state.loan_lenders_analytics,
  }),
  loan_borrowers_info: (state) => ({
    loan_borrowers: state.loan_borrowers,
    current_page: state.loan_borrowers_page,
    last_page: state.loan_borrowers_last_page,
  }),
  loan_borrowers_analytics_info: (state) => ({
    loan_borrowers_analytics: state.loan_borrowers_analytics,
  }),
};

const actions = {
  fetchLoanLimit: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "loans/client/loan-limit?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const data = response.data;

      commit("SET_LOAN_LIMIT", data);
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchGroupLoanLimit: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "loans/group/loan-limit?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const data = response.data;

      commit("SET_GROUP_LOAN_LIMIT", data);
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchLoanProfiles: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "loanprofile/view?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_LOAN_PROFILES", {
        data,
        last_page,
        current_page: params.page,
      });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchSpecificLoanGuarantors: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "loans/guarantors?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_SPECIFIC_LOAN_GUARANTORS", {
        data,
        last_page,
        current_page: params.page,
      });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchLoanLenders: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "loans/lenders/view?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_LOAN_LENDERS", {
        data,
        last_page,
        current_page: params.page,
      });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchBorrowersLenders: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "loans/borrowers/view?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_LOAN_BORROWERS", {
        data,
        last_page,
        current_page: params.page,
      });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchLoanGuarantors: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "loanprofile/guarantors?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_LOAN_GUARANTORS", {
        data,
        last_page,
        current_page: params.page,
      });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchLoanGuarantorsPaginated: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "loanprofile/guarantors?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_LOAN_GUARANTORS_PAGINATED", {
        data,
        last_page,
        current_page: params.page,
      });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchGroupsBorrowers: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "loanprofile/group_guarantors?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_GROUPS", { data, last_page, current_page: params.page });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchGroupsBorrowersPaginated: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "loanprofile/group_guarantors?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_GROUPS_PAGINATED", {
        data,
        last_page,
        current_page: params.page,
      });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  createLoan: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "loans/create";
      const response = await Axios.post(url, form, headers);
      const { message, data } = response.data;

      commit("ADD_LOAN", data);
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchClientBorrowerDetails: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "loans/borrower/detail?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data } = response.data;

      commit("SET_LOAN_BORROWER_DETAILS", data);
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  exportLoanLenders: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: false, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "loans/lenders/export?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { message } = response.data;

      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  exportLoanBorrowers: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: false, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "loans/borrowers/export?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { message } = response.data;

      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchLoanLendersAnalytics: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "loans/lenders/analytics?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data } = response.data;

      commit("SET_LOAN_LENDERS_ANALYTICS", data);
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchLoanBorrowersAnalytics: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "loans/borrowers/analytics?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data } = response.data;

      commit("SET_LOAN_BORROWERS_ANALYTICS", data);
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
};

const mutations = {
  SET_LOAN_LIMIT: (state, newValue) => {
    state.loan_limit = newValue.data;
  },
  SET_GROUP_LOAN_LIMIT: (state, newValue) => {
    state.group_loan_limit = newValue.data;
  },
  SET_LOAN_PROFILES: (state, newValue) => {
    (state.loanprofiles = newValue.data),
      (state.loanprofiles_page = newValue.current_page);
    state.loanprofiles_last_page = newValue.last_page;
  },
  SET_LOAN_LENDERS: (state, newValue) => {
    state.loan_lenders = newValue.data;
    state.loan_lenders_page = newValue.current_page;
    state.loan_lenders_last_page = newValue.last_page;
  },
  SET_LOAN_LENDERS_ANALYTICS: (state, newValue) => {
    state.loan_lenders_analytics = newValue || {};
  },
  SET_LOAN_BORROWERS_ANALYTICS: (state, newValue) => {
    state.loan_borrowers_analytics = newValue || {};
  },
  SET_LOAN_BORROWERS: (state, newValue) => {
    state.loan_borrowers = newValue.data;
    state.loan_borrowers_page = newValue.current_page;
    state.loan_borrowers_last_page = newValue.last_page;
  },
  SET_LOAN_GUARANTORS: (state, newValue) => {
    state.guarantors = newValue.data;
    state.guarantors_page = newValue.current_page;
    state.guarantors_last_page = newValue.last_page;
  },
  SET_LOAN_BORROWER_DETAILS: (state, newValue) => {
    state.loan_borrower = newValue || {};
  },
  SET_SPECIFIC_LOAN_GUARANTORS: (state, newValue) => {
    state.loan_guarantors = newValue.data;
    state.loan_guarantors_page = newValue.current_page;
    state.loan_guarantors_last_page = newValue.last_page;
  },
  SET_LOAN_GUARANTORS_PAGINATED: (state, newValue) => {
    state.guarantors = [...state.guarantors, newValue.data];
    state.guarantors_page = newValue.current_page;
    state.guarantors_last_page = newValue.last_page;
  },
  SET_GROUPS: (state, newValue) => {
    state.groups_guarantors = newValue.data;
    state.groups_guarantors_page = newValue.current_page;
    state.groups_guarantors_last_page = newValue.last_page;
  },
  SET_GROUPS_PAGINATED: (state, newValue) => {
    state.groups_guarantors = [...state.groups, newValue.data];
    state.groups_guarantors_page = newValue.current_page;
    state.groups_guarantors_last_page = newValue.last_page;
  },
  ADD_LOAN: (state, newValue) => {
    console.log("data", newValue);
    // state.loans.unshift(newValue)
  },
  UPDATE_GROUP: (state, newValue) => {
    let newArray = state.groups.map((item) => {
      if (item.id == newValue.id) {
        item = newValue;
      }
      return item;
    });
    state.groups = newArray;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
