const state = {
  layoutType: 'vertical'
}

const getters = {
  layoutType: (state) => state.layoutType
}

const mutations = {
  CHANGE_LAYOUT(state, layoutType) {
    state.layoutType = layoutType;
  }
}

const actions = {
  changeLayoutType({ commit }, { layoutType }) {
    commit('CHANGE_LAYOUT', layoutType);
  },
}

export default {
  state,
  getters,
  actions,
  mutations
};