import { tokenHeaders, tokenMultiPartHeaders } from "../../services/helpers";
import Axios from "axios";

const ADMIN_URL = process.env.VUE_APP_ADMIN_URL;
const state = {
  users: [],
  users_page: 1,
  users_last_page: 1,

  countries: [],
  countries_page: 1,
  countries_last_page: 1,

  user_notifications: [],
  user_notifications_page: 1,
  user_notifications_last_page: 1,

  user_details: {},

  user_documents: {},
  user_documents_page: 1,
  users_documents_last_page: 1,

  client_notifications: [],
  client_notifications_page: 1,
  client_notifications_last_page: 1,

  custom_bulk_messages: [],
  custom_messages_page: 1,
  custom_messages_last_page: 1,

  notification_topics: [],
  notification_topics_page: 1,
  notification_topics_last_page: 1,

  notification_topic_details: {},

  mpesa_codes: [],
  wallet_details: {},

  wallet_transactions: [],
  wallet_transactions_current_page: 1,
  wallet_transactions_last_page: 1,

  wallet_transfers: [],
  wallet_transfers_page: 1,
  wallet_transfers_last_page: 1,
};

const getters = {
  users_info: (state) => ({
    users: state.users,
    current_page: state.users_page,
    last_page: state.users_last_page,
  }),
  user_details: (state) => state.user_details,
  mpesa_codes: (state) => state.mpesa_codes,
  countries_info: (state) => ({
    countries: state.countries,
    current_page: state.countries_page,
    last_page: state.countries_last_page,
  }),
  user_notifications_info: (state) => ({
    user_notifications: state.user_notifications,
    current_page: state.user_notifications_page,
    last_page: state.user_notifications_last_page,
  }),
  client_notifications_info: (state) => ({
    client_notifications: state.client_notifications,
    current_page: state.client_notifications_page,
    last_page: state.client_notifications_last_page,
  }),
  custom_bulk_info: (state) => ({
    custom_messages: state.custom_bulk_messages,
    current_page: state.custom_messages_page,
    last_page: state.custom_messages_last_page,
  }),
  notification_topics_info: (state) => ({
    notification_topics: state.notification_topics,
    current_page: state.notification_topics_page,
    last_page: state.notification_topics_last_page,
  }),
  notification_topic_details: (state) => state.notification_topic_details,
  wallet_details: (state) => state.wallet_details,
  wallet_transactions: (state) => ({
    transactions: state.wallet_transactions,
    current_page: state.wallet_transactions_current_page,
    last_page: state.wallet_transactions_last_page,
  }),
  user_documents: (state) => ({
    user_documents: state.user_documents,
    current_page: state.user_documents_page,
    last_page: state.users_documents_last_page,
  }),
  wallet_transfers: (state) => ({
    wallet_transfers: state.wallet_transfers,
    current_page: state.wallet_transfers_page,
    last_page: state.wallet_transfers_last_page,
  }),
};

const actions = {
  fetchClients: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "clients/view?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_USERS", { data, last_page, current_page: params.page });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchClientDetails: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "clients/retrieve?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }

      const response = await Axios.get(url, headers);
      const { data } = response.data;

      commit("SET_USER_DETAILS", data);
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchMpesaCode: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "clients/mpesa/codes?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }

      const response = await Axios.get(url, headers);
      const { data } = response.data;
      let mpesa_data = JSON.parse(data);

      commit("SET_MPESA_CODE", mpesa_data);
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchClientsPaginated: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "clients/view?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_USERS_PAGINATED", {
        data,
        last_page,
        current_page: params.page,
      });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchCountries: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "users/countries?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_COUNTRIES", { data, last_page, current_page: params.page });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchCountriesPaginated: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "users/countries?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_COUNTRIES_PAGINATED", {
        data,
        last_page,
        current_page: params.page,
      });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  exportUsers: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "clients/export?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { message } = response.data;

      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  exportUsersPdf: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "clients/pdf/export?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { message } = response.data;

      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  exportUserAccounts: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: false, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "accounts/client/export?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { message } = response.data;

      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  exportUserAccountsPdf: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: false, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "accounts/client/pdf/export?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { message } = response.data;

      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  exportUserStatementPdf: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: false, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "accounts/client_statement/pdf/export?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { message } = response.data;

      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  exportUserPassivePdf: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: false, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "accounts/client_passive/pdf/export?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { message } = response.data;

      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  exportUserLoansPdf: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: false, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "accounts/client_loan/pdf/export?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { message } = response.data;

      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  exportUserTransactionsPdf: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: false, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "accounts/client_transactions/pdf/export?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { message } = response.data;

      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchUserBulkSms: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "messages/bulk/view?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }

      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_USER_NOTIFICATIONS", {
        data,
        last_page,
        current_page: params.page,
      });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  createUserBulkSms: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "messages/bulk/create";
      const response = await Axios.post(url, form, headers);
      const { message, data } = response.data;

      commit("ADD_USER_NOTIFICATION", data);
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  uploadBulkNotification: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenMultiPartHeaders({ ip_address, token });

      let url = ADMIN_URL + "messages/bulk/upload";
      const response = await Axios.post(url, form, headers);
      const { message, data } = response.data;

      commit("ADD_USER_NOTIFICATION", data);
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  fetchClientNotifications: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "notifications/client?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }

      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_CLIENT_NOTIFICATIONS", {
        data,
        last_page,
        current_page: params.page,
      });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchAdminNotifications: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "notifications/admin?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }

      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_CLIENT_NOTIFICATIONS", {
        data,
        last_page,
        current_page: params.page,
      });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  createdUserNotification: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenMultiPartHeaders({ ip_address, token });

      let url = ADMIN_URL + "notifications/create";
      const response = await Axios.post(url, form, headers);
      const { message, data } = response.data;

      commit("ADD_CLIENT_NOTIFICATION", data);
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  createAllClientsNotification: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenMultiPartHeaders({ ip_address, token });

      let url = ADMIN_URL + "notifications/create/all";
      const response = await Axios.post(url, form, headers);
      const { message } = response.data;

      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  uploadBulkClientNotifications: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenMultiPartHeaders({ ip_address, token });

      let url = ADMIN_URL + "messages/bulk/upload";
      const response = await Axios.post(url, form, headers);
      const { message } = response.data;

      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchCustomMessages: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "custom/messages/view?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }

      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_CUSTOM_BULK_SMS", {
        data,
        last_page,
        current_page: params.page,
      });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  createCustomMessage: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "custom/messages/create";
      const response = await Axios.post(url, form, headers);
      const { message, data } = response.data;

      commit("ADD_CUSTOM_BULK_SMS", data);
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  updateCustomMessage: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "custom/messages/update";
      const response = await Axios.post(url, form, headers);
      const { message, data } = response.data;

      commit("UPDATE_CUSTOM_BULK_SMS", data);
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  deleteCustomMessage: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "custom/messages/delete?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }

      const response = await Axios.delete(url, headers);
      const { message } = response.data;

      commit("DELETE_CUSTOM_BULK_SMS", params.message_id);
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchNotificationTopics: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "notifications/topic/view?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }

      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_NOTIFICATION_TOPICS", {
        data,
        last_page,
        current_page: params.page,
      });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchNotificationTopicDetails: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "notifications/topic/detail?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }

      const response = await Axios.get(url, headers);
      const { data } = response.data;

      commit("SET_NOTIFICATION_DETAILS_TOPICS", data);
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchTopicNotifications: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "notifications/topic/notifications?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }

      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_CLIENT_NOTIFICATIONS", {
        data,
        last_page,
        current_page: params.page,
      });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  createNotificationTopics: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenMultiPartHeaders({ ip_address, token });

      let url = ADMIN_URL + "notifications/topic/create";
      const response = await Axios.post(url, form, headers);
      const { message, data } = response.data;

      commit("ADD_NOTIFICATION_TOPICS", data);
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  fetchWalletDetails: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "wallet/retrive?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }

      const response = await Axios.get(url, headers);
      const { data } = response.data;

      commit("SET_WALLET_DETAILS", data);
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  fetchWalletTransactions: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "wallet/transactions?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }

      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_WALLET_TRANSACTIONS", {
        data,
        last_page,
        current_page: params.page,
      });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  adjustWalletAmount: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "wallet/update/amount";
      const response = await Axios.post(url, form, headers);
      const { message, data } = response.data;

      commit("SET_WALLET_DETAILS", data);
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  withdrawalWallet: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "wallet/initiate/withdrawal";
      const response = await Axios.post(url, form, headers);
      const { message } = response.data;

      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  fetchWalletTransfers: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "wallet/transfers/view?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }

      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_WALLET_TRANSFERS", {
        data,
        last_page,
        current_page: params.page,
      });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  fetchWalletTransferTransactions: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "wallet/transfer/transactions?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }

      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_WALLET_TRANSACTIONS", {
        data,
        last_page,
        current_page: params.page,
      });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  createWalletTransfer: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "wallet/initiate/create";
      const response = await Axios.post(url, form, headers);
      const { message } = response.data;

      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  verifyClient: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "clients/verify";
      const response = await Axios.post(url, form, headers);
      const { message } = response.data;

      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  manualClientVerification: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "clients/verify/manual";
      const response = await Axios.post(url, form, headers);
      const { message } = response.data;

      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchUserDocuments: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "wallet/transactions?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }

      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_WALLET_TRANSACTIONS", {
        data,
        last_page,
        current_page: params.page,
      });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  updateUserCanTransact: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "clients/transact";
      const response = await Axios.post(url, form, headers);
      const { message, data } = response.data;

      commit("UPDATE_USER", data);
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  exportCurrentAccounts: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "clients/export/current?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }

      const response = await Axios.get(url, headers);
      const { message } = response.data;

      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
};

const mutations = {
  SET_USERS: (state, newValue) => {
    state.users = newValue.data;
    state.users_page = newValue.current_page;
    state.users_last_page = newValue.last_page;
  },
  SET_USERS_PAGINATED: (state, newValue) => {
    state.users = [...state.users, ...newValue.data];
    state.users_page = newValue.current_page;
    state.users_last_page = newValue.last_page;
  },
  SET_USER_DETAILS: (state, newValue) => {
    state.user_details = newValue;
  },
  UPDATE_USER: (state, newValue) => {
    let newArray = state.users.map((item) => {
      if (item.id == newValue.id) {
        item = newValue;
      }

      return item;
    });

    state.users = newArray;
  },
  SET_COUNTRIES: (state, newValue) => {
    state.countries = newValue.data;
    state.countries_page = newValue.current_page;
    state.countries_last_page = newValue.last_page;
  },
  SET_COUNTRIES_PAGINATED: (state, newValue) => {
    state.countries = [...state.countries, ...newValue.data];
    state.countries_page = newValue.current_page;
    state.countries_last_page = newValue.last_page;
  },
  SET_USER_NOTIFICATIONS: (state, newValue) => {
    state.user_notifications = newValue.data;
    state.user_notifications_page = newValue.current_page;
    state.user_notifications_last_page = newValue.last_page;
  },
  ADD_USER_NOTIFICATION: (state, newValue) => {
    state.user_notifications = [newValue, ...state.user_notifications];
  },
  SET_CLIENT_NOTIFICATIONS: (state, newValue) => {
    state.client_notifications = newValue.data;
    state.client_notifications_page = newValue.current_page;
    state.client_notifications_last_page = newValue.last_page;
  },
  ADD_CLIENT_NOTIFICATION: (state, newValue) => {
    state.client_notifications = [newValue, ...state.client_notifications];
  },
  SET_CUSTOM_BULK_SMS: (state, newValue) => {
    state.custom_bulk_messages = newValue.data;
    state.custom_messages_page = newValue.current_page;
    state.custom_messages_last_page = newValue.last_page;
  },
  ADD_CUSTOM_BULK_SMS: (state, newValue) => {
    state.custom_bulk_messages = [newValue, ...state.custom_bulk_messages];
  },
  UPDATE_CUSTOM_BULK_SMS: (state, newValue) => {
    let newArray = state.custom_bulk_messages.map((item) => {
      if (item.id == newValue.id) {
        item = newValue;
      }
      return item;
    });
    state.custom_bulk_messages = newArray;
  },
  DELETE_CUSTOM_BULK_SMS: (state, newValue) => {
    let newArray = state.custom_bulk_messages.filter(
      (item) => item.id != newValue
    );
    state.custom_bulk_messages = newArray;
  },
  SET_MPESA_CODE: (state, newValue) => {
    state.mpesa_codes = newValue;
  },
  SET_NOTIFICATION_TOPICS: (state, newValue) => {
    state.notification_topics = newValue.data;
    state.notification_topics_page = newValue.current_page;
    state.notification_topics_last_page = newValue.last_page;
  },
  SET_NOTIFICATION_DETAILS_TOPICS: (state, newValue) => {
    state.notification_topic_details = newValue;
  },
  ADD_NOTIFICATION_TOPICS: (state, newValue) => {
    state.notification_topics = [newValue, ...state.notification_topics];
  },
  SET_WALLET_DETAILS: (state, newValue) => {
    state.wallet_details = newValue;
  },
  SET_WALLET_TRANSACTIONS: (state, newValue) => {
    state.wallet_transactions = newValue.data;
    state.wallet_transactions_current_page = newValue.current_page;
    state.wallet_transactions_last_page = newValue.last_page;
  },
  SET_WALLET_TRANSFERS: (state, newValue) => {
    state.wallet_transfers = newValue.data;
    state.wallet_transfers_page = newValue.current_page;
    state.wallet_transfers_last_page = newValue.last_page;
  },
  ADD_WALLET_TRANSFER: (state, newValue) => {
    state.wallet_transfers = [newValue, ...state.wallet_transfers];
  },
  SET_USER_DOCUMENTS: (state, newValue) => {
    state.user_documents = newValue.data;
    state.user_documents_page = newValue.current_page;
    state.users_documents_last_page = newValue.last_page;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
