import Vue from 'vue'
import {
    $capitalizeFirstLetter,
    $formatNumber,
    $getDateString,
    $toCurrency,
    $clone,
    $nullToEmpty,
    $searchInObject,
    $getDateDisplay
} from './helpers'

Vue.mixin({
    methods: {
        $capitalizeFirstLetter,
        $formatNumber,
        $getDateString,
        $toCurrency,
        $clone,
        $nullToEmpty,
        $searchInObject,
        $getDateDisplay,
        $toast(variant, title, message) {
            this.$bvToast.toast(message, { title: title, variant: `${variant || 'default'}`, solid: true })
        },
        $toastSuccess(message) {
            this.$bvToast.toast(message, { title: 'Success', variant: `success`, solid: true })
        },
        $toastError(message) {
            this.$bvToast.toast(message, { title: 'Error', variant: `danger`, solid: true })
        },
    }    
})
