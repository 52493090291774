import { tokenHeaders, tokenMultiPartHeaders } from "../../services/helpers";
import Axios from "axios";

const ADMIN_URL = process.env.VUE_APP_ADMIN_URL;

const state = {
  payment_methods: [],
  payment_methods_page: 1,
  payment_methods_last_page: 1,
};

const getters = {
  payment_methods_info: (state) => ({
    methods: state.payment_methods,
    current_page: state.payment_methods_page,
    last_page: state.payment_methods_last_page,
  }),
};

const actions = {
  fetchPaymentMethods: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "payment/method/list?";
      for (let key in params) if (params[key]) url += `&${key}=${params[key]}`;

      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_PAYMENT_METHODS", {
        data,
        last_page,
        current_page: params.page,
      });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  updatePaymentMethod: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );

    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "payment/method/update";
      const response = await Axios.post(url, form, headers);
      const { message, data } = response.data;

      commit("UPDATE_PAYMENT_METHODS", data);
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.respose.data;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  updatePaymentMethodAutomationStatus: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "payment/method/automatic/update";
      const response = await Axios.post(url, form, headers);
      const { message, data } = response.data;

      commit("UPDATE_PAYMENT_METHODS", data);
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.respose.data;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
};

const mutations = {
  SET_PAYMENT_METHODS: (state, newValue) => {
    const { data, current_page, last_page } = newValue;

    state.payment_methods = data ?? [];
    state.payment_methods_page = current_page ?? 1;
    state.payment_methods_last_page = last_page ?? 1;
  },
  UPDATE_PAYMENT_METHODS: (state, newValue) => {
    let newArray = state.payment_methods.map((item) => {
      if (item.id == newValue.id) item = newValue;

      return item;
    });

    state.payment_methods = newArray;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
