<template>
    <a v-b-tooltip.hover @click.prevent="$emit('click')" title="Delete" href="#" class="text-danger ml-2 mr-2">
        <i class="mdi mdi-close font-size-18"></i>
    </a>
</template>

<script>
export default {

}
</script>

<style>

</style>