import Axios from "axios";
import {
  tokenHeaders,
  customParse,
  tokenBotHeaders,
} from "../../services/helpers";

const ADMIN_URL = process.env.VUE_APP_ADMIN_URL;
const BOT_URL = process.env.VUE_APP_CHAT_URL + "accounts/auth/";

const state = {
  currentUser: customParse(localStorage.getItem("currentUser")),
  bot_auth: localStorage.getItem("bot_token"),
  bot_user: customParse(localStorage.getItem("bot_user")),
  auth_token: localStorage.getItem("token"),
  ip_address: localStorage.getItem("ip_address"),
  auth_loading: false,
  auth_message: "",
  auth_error: false,
};

const getters = {
  auth_info: (state) => {
    return {
      token: state.auth_token,
      ip_address: state.ip_address,
      loading: state.auth_loading,
      message: state.auth_message,
      error: state.auth_error,
    };
  },
  botAuth: (state) => state.bot_auth,
  currentUser: (state) => state.currentUser,
  loggedIn: (state) => state.token && state.token.length > 1,
  userType: (state) => (state.currentUser ? state.currentUser.type : null),
  apiToken: (state) => (state.currentUser ? state.currentUser.token : null),
  roles: (state) => (state.currentUser ? state.currentUser.roles : []),
};

const actions = {
  setCurrentUser: ({ commit }, user) => commit("SET_CURRENT_USER", user),
  removeCurrentUser: ({ commit }) => commit("SET_CURRENT_USER", null),
  fetchAdmin: async ({ commit }, ip_address) => {
    commit("SET_IP_ADDRESS", ip_address);
    commit("AUTH_LOADING", { loading: true, message: "", error: false });

    try {
      let params = { ip_address, token: state.auth_token };
      const headers = tokenHeaders(params);

      const url = ADMIN_URL + "auth/user";
      let response = await Axios.get(url, headers);
      const { data } = response.data;

      commit("AUTH_LOADING", { loading: false, message: "", error: false });
      commit("SET_AUTH_USER", { data });
    } catch (error) {
      const error_message = "Please log in to continue";
      commit("AUTH_LOADING", {
        loading: false,
        message: error_message,
        error: true,
      });
      commit("LOG_OUT");
    }
  },
  fetchBotUser: async ({ commit }) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let token = state.bot_auth;
      const headers = tokenBotHeaders({ token });

      let url = BOT_URL + "user";
      const response = await Axios.get(url, headers);

      commit("SET_BOT_USER", response.data);
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
      commit("CLEAR_AUTH_BOT");
    }
  },
  loginAdmin: async ({ state, commit }, body) => {
    commit("AUTH_LOADING", { loading: true, message: "", error: false });

    try {
      const req_body = { ip_address: state.ip_address, ...body };
      const url = ADMIN_URL + "auth/login";
      let response = await Axios.post(url, req_body);
      let { data, token, message } = response.data;

      commit("AUTH_LOADING", {
        loading: false,
        message: message,
        error: false,
      });
      commit("SET_AUTH_USER", { data, token });
    } catch (error) {
        console.log(error);
      const message = error.response.data?.message;
      commit("AUTH_LOADING", { loading: false, message: message, error: true });
      commit("LOG_OUT");
    }
  },
  loginBot: async ({ state, commit, rootState }, body) => {
    commit("AUTH_LOADING", { loading: true, message: "", error: false });
    try {
      const req_body = { ip_address: state.ip_address, ...body };
      const url = BOT_URL + "login";
      let response = await Axios.post(url, req_body);
      let { user, token, message } = response.data;

      commit("AUTH_LOADING", {
        loading: false,
        message: message,
        error: false,
      });
      commit("SET_BOT_USER", user);
      commit("SET_AUTH_BOT", token);
    } catch (error) {
      const message = error.response.data?.message;
      commit("AUTH_LOADING", { loading: false, message: message, error: true });
      commit("CLEAR_AUTH_BOT");
    }
  },
};

const mutations = {
  SET_CURRENT_USER: (state, newValue) => {
    state.currentUser = newValue;
    if (!!newValue)
      window.localStorage.setItem("currentUser", JSON.stringify(newValue));
    else window.localStorage.removeItem("currentUser");
  },
  LOG_OUT: (state) => {
    localStorage.removeItem("currentUser");
    localStorage.removeItem("token");
    localStorage.removeItem("bot_token");
    localStorage.removeItem("ip_address");

    state.currentUser = "";
    state.auth_token = "";
    state.bot_auth = "";
  },
  AUTH_LOADING: (state, new_value) => {
    state.auth_loading = new_value.loading;
    state.auth_error = new_value.error;
    state.auth_message = new_value.message;
  },
  SET_AUTH_USER: (state, new_value) => {
    const { data, token } = new_value;
    state.currentUser = data;
    localStorage.setItem("currentUser", JSON.stringify(data));

    if (token) {
      localStorage.setItem("token", token);
      state.auth_token = token;
    }
  },
  SET_AUTH_BOT: (state, newValue) => {
    localStorage.setItem("bot_token", newValue);
    state.bot_auth = newValue;
  },
  SET_BOT_USER: (state, newValue) => {
    let user = JSON.stringify(newValue);
    localStorage.setItem("bot_user", user);

    state.bot_user = user;
  },
  CLEAR_AUTH_BOT: (state, newValue) => {
    localStorage.removeItem("bot_token");
    localStorage.removeItem("bot_user");
    state.bot_auth = "";
    state.bot_user = "";
  },
  SET_IP_ADDRESS: (state, newValue) => {
    state.ip_address = newValue;
    localStorage.setItem("ip_address", newValue);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
