import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App.vue";

import store from "./state/store";
import router from "./router/index";

import BootstrapVue from "bootstrap-vue";
import vco from "v-click-outside";
Vue.use(VueRouter);

import moment from "moment";
Vue.prototype.moment = moment;

import instance from "./services/axios-helper";
Vue.prototype.$http = instance;

import GlobalComponents from "./services/global-components";
Vue.use(GlobalComponents);

import "@/design/index.scss";
import "./services/mixins";
import "./services/directives";

Vue.use(BootstrapVue);
Vue.use(vco);

import Scrollspy from "vue2-scrollspy";
Vue.use(Scrollspy);

const VueScrollTo = require("vue-scrollto");
Vue.use(VueScrollTo);

import VueApexCharts from "vue-apexcharts";
Vue.component("apexchart", VueApexCharts);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
