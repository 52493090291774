import moment from "moment";

export const $capitalizeFirstLetter = (str) => {
  (str) => str.charAt(0).toUpperCase() + str.slice(1);
};

export const $formatNumber = (number, decimals = 2) => {
  return parseFloat(number).toFixed(decimals);
};

export const $getDateString = (date = new Date()) => {
  return date.toISOString().substring(0, 10);
};

export const $toCurrency = (number) => {
  if (number == null) return "—";
  let currency = parseFloat(number)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  return number < 0 ? "(" + currency.substring(1) + ")" : currency;
};

// Copy object that does not contain functions et. al
export const $clone = (object) => {
  return JSON.parse(JSON.stringify(object));
};

export const $nullToEmpty = (value) => {
  return value || "";
};

export const $searchInObject = (obj, text) => {
  return Object.keys(obj || []).some((key) =>
    String(obj[key])
      .toLowerCase()
      .includes(text.toLowerCase())
  );
};

export const $getDateDisplay = (date) => {
  return date ? moment(date).format("MMM DD, HH:mm") : "—";
};

export const customParse = (json_string) => {
  let is_undefined = json_string == 'undefined';
  const data =
   !is_undefined && json_string && json_string.length > 1 ? JSON.parse(json_string) : "";

  return data;
};

export const tokenHeaders = (state) => {
  let config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  if (state.token) config.headers["Authorization"] = `Token ${state.token}`;
  if (state.ip_address) config.headers["Api-Key"] = state.ip_address;

  return config;
};

export const tokenMultiPartHeaders = (state) => {
  let config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
  };

  if (state.token) config.headers["Authorization"] = `Token ${state.token}`;
  if (state.ip_address) config.headers["Api-Key"] = state.ip_address;

  return config;
};

export const tokenBotHeaders = (state) => {
  let config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  if (state.token) config.headers["Authorization"] = `Token ${state.token}`;

  return config;
}