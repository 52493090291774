<template>
  <router-link :to="to" class="btn btn-primary btn-sm btn-rounded">{{ title }}</router-link>
</template>

<script>
export default {
    props: [ 'to', 'title' ]
}
</script>

<style>

</style>