import { tokenHeaders } from "../../services/helpers";
import Axios from "axios";

let notificationsHandle = 0;
const ADMIN_URL = process.env.VUE_APP_ADMIN_URL;

const state = {
  notifications: [],
  notifications_page: 1,
  notification_last_page: 1,
};

const getters = {
  notification_info: (state) => ({
    notifications: state.notifications,
    current_page: state.current_page,
    last_page: state.last_page,
  }),
};

const mutations = {
  SET_NOTIFICATIONS: (state, newValue) => {
    state.notifications = newValue.data;
    state.notifications_page = newValue.current_page;
    state.notification_last_page = newValue.last_page;
  },
};

const actions = {
  fetchNotifications: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "notifications/view?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_NOTIFICATIONS", {
        data,
        last_page,
        current_page: params.page,
      });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response?.data;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
