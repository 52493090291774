import store from "@/state/store";

export default [
  {
    path: "/home",
    name: "guest-home",
    component: () => import("@/pages/guest/home"),
  },
  {
    path: "/",
    name: "home",
    meta: { authRequired: true },
    component: () => import("@/pages/home/index"),
  },

  {
    path: "/clients",
    name: "clients",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/clients/clients"),
  },
  {
    path: "/clients/documents",
    name: "client-documents",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/clients/documents"),
  },
  {
    path: "/clients/connections",
    name: "client-connections",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/clients/connections"),
  },
  {
    path: "/clients/payment-methods",
    name: "client-payment-methods",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/clients/payment-methods"),
  },
  {
    path: "/clients/next-of-kin",
    name: "client-next-of-kin",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/clients/next-of-kin"),
  },
  {
    path: "/clients/surveys",
    name: "client-surveys",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/clients/surveys"),
  },

  {
    path: "/clients/watchlist",
    name: "client-watchlist",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/clients/watchlist"),
  },

  {
    path: "/clients/watchlist/create",
    name: "client-watchlist-create",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/clients/watchlist/create"),
  },

  {
    path: "/clients/blacklist",
    name: "client-blacklist",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/clients/black-list"),
  },

  {
    path: "/clients/blacklist/details/:id",
    name: "client-blacklist-details",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/clients/black-list/details"),
  },

  {
    path: "/clients/:id/general",
    name: "client-details-general",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/clients/details/general"),
  },
  {
    path: "/clients/:id/goals",
    name: "client-details-goals",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/clients/details/goals"),
  },
  {
    path: "/clients/:id/payment/methods",
    name: "client-details-payment-methods",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/clients/details/payment_methods"),
  },

  {
    path: "/clients/:id/deposits",
    name: "client-details-deposits",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/clients/details/deposits"),
  },
  {
    path: "/clients/:id/withdrawals",
    name: "client-details-withdrawals",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/clients/details/withdrawals"),
  },
  {
    path: "/clients/:id/loans",
    name: "client-details-loans",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/clients/details/loans"),
  },
  {
    path: "/clients/:id/transfers",
    name: "client-details-transfers",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/clients/details/transfers"),
  },
  {
    path: "/clients/:id/transfers-create",
    name: "client-details-transfers-create",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/clients/details/transfers/create"),
  },
  {
    path: "/clients/:id/utility",
    name: "client-details-utility",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/clients/details/utility"),
  },
  {
    path: "/clients/:id/transactions",
    name: "client-details-transactions",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/clients/details/transactions"),
  },
  {
    path: "/clients/:id/budgets",
    name: "client-details-budgets",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/clients/details/budgets"),
  },
  {
    path: "/clients/:id/lotteries",
    name: "client-details-lotteries",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/clients/details/lotteries"),
  },
  {
    path: "/clients/:id/points",
    name: "client-details-points",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/clients/details/points"),
  },
  {
    path: "/clients/:id/wallet",
    name: "client-view-wallet",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/clients/details/wallet"),
  },
  {
    path: "/clients/:id/loan-limit",
    name: "client-view-loan-limit",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/clients/details/loan_limit"),
  },

  {
    path: "/clients/:id/verify",
    name: "client-verify",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/clients/details/general/verify"),
  },

  {
    path: "/clients/:id/manual/verify",
    name: "client-manual-verify",
    meta: { authRequired: true },
    component: () =>
      import("@/pages/admin/clients/details/general/manual_verify"),
  },

  {
    path: "/goals",
    name: "goals",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/goals/goals"),
  },
  {
    path: "/goals/create",
    name: "goals-create",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/goals/create"),
  },
  {
    path: "/goals/summary",
    name: "goals-summary",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/goals/goals"),
  },
  {
    path: "/goals/transfers/create",
    name: "goal-transfers-create",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/goals/transfer"),
  },
  {
    path: "/goals/withdrawal",
    name: "goal-withdrawal",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/goalwithdrawals"),
  },
  {
    path: "/goal/withdrawal/create",
    name: "goal-withdrawal-create",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/goalwithdrawals/create"),
  },
  {
    path: "/goals/:id",
    name: "goal-details",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/goals/details"),
  },

  {
    path: "/investment-classes",
    name: "investment-classes",
    meta: { authRequired: true },
    component: () =>
      import("@/pages/admin/investment-classes/investment-classes"),
  },
  {
    path: "/investment-classes/:id",
    name: "investment-class-details",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/investment-classes/details"),
  },
  {
    path: "/investments",
    name: "investments",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/investments/investments"),
  },

  {
    path: "/investments/create",
    name: "investments-create",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/investments/create"),
  },

  {
    path: "/investments/edit/:id",
    name: "investments-edit",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/investments/edit"),
  },

  {
    path: "/investments/:id",
    name: "investment-details",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/investments/details"),
  },
  {
    path: "/investment-survey",
    name: "investment-survey",
    meta: { authRequired: true },
    component: () =>
      import("@/pages/admin/investment-survey/investment-survey"),
  },
  {
    path: "/investment-survey/details/:id",
    name: "investment-survey-details",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/investment-survey/details"),
  },
  {
    path: "/srt-offers",
    name: "srt_offers",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/srt_offers/offers"),
  },
  {
    path: "/srt-offers/:id",
    name: "srt_offers_details",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/srt_offers/details"),
  },
  {
    path: "/savings",
    name: "savings",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/savings/savings"),
  },
  {
    path: "/savings/:id",
    name: "saving-details",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/savings/details"),
  },

  {
    path: "/groups",
    name: "groups",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/groups/groups"),
  },
  {
    path: "/groups/create",
    name: "create-admin-groups",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/groups/groups/form"),
  },
  {
    path: "/groups/:id/general",
    name: "group-details-general",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/groups/details/general"),
  },
  {
    path: "/groups/:id/members",
    name: "group-details-members",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/groups/details/members"),
  },
  {
    path: "/groups/:id/member-goals",
    name: "group-details-member-goals",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/groups/details/member-goals"),
  },
  {
    path: "/groups/:id/goals",
    name: "group-details-goals",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/groups/details/goals"),
  },
  {
    path: "/groups/:id/loans",
    name: "group-details-loans",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/groups/details/loans"),
  },
  {
    path: "/groups/:id/transactions",
    name: "group-details-transactions",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/groups/details/transactions"),
  },
  {
    path: "/groups/:id/loan-limit",
    name: "group-details-loan-limit",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/groups/details/loan_limit"),
  },
  {
    path: "/groups/:id/collaterals",
    name: "group-details-collaterals",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/groups/details/member-collaterals"),
  },

  {
    path: "/groups/group-registration-requests",
    name: "group-registration-requests",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/groups/group-registration-requests"),
  },
  {
    path: "/groups/group-registration-requests/:id/general",
    name: "group-registration-requests-details",
    meta: { authRequired: true },
    component: () =>
      import("@/pages/admin/groups/group-registration-requests/details"),
  },

  {
    path: "/groups/group-validation-requests",
    name: "group-validation-requests",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/groups/group-validation-requests"),
  },
  {
    path: "/groups/group-validation-requests/:id/general",
    name: "group-validation-requests-details",
    meta: { authRequired: true },
    component: () =>
      import("@/pages/admin/groups/group-validation-requests/details"),
  },

  {
    path: "/groups/group-withdrawal-requests",
    name: "group-withdrawal-requests",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/groups/group-withdrawals-requests"),
  },

  {
    path: "/payments",
    name: "payments",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/payments/payments"),
  },
  {
    path: "/allocation/create",
    name: "allocation-create",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/payments/create/form"),
  },
  {
    path: "/payment/allocation",
    name: "payment-allocation",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/payments/create"),
  },
  {
    path: "/payments/schedule/create",
    name: "payments-create",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/payments/payment-schedule/form"),
  },
  {
    path: "/payments/unit_prices",
    name: "payments-payment-unit-prices",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/payments/unit-price"),
  },
  {
    path: "/payments/schedule/payments",
    name: "payments-payment-schedule",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/payments/payment-schedule"),
  },
  {
    path: "/payments/:id",
    name: "payment-details",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/payments/details"),
  },

  {
    path: "/loans",
    name: "loans",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/loans/loans"),
  },
  {
    path: "/loans/create",
    name: "loans-create",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/loans/loans/form"),
  },
  {
    path: "/loans/security/funds",
    name: "loan-security-funds",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/security-funds"),
  },
  {
    path: "/loans/reports",
    name: "loan-reports",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/loans/reports"),
  },
  {
    path: "/loans/profiles",
    name: "loan-profiles",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/loan-profiles"),
  },
  {
    path: "/loans/guarantors",
    name: "loan-guarantors",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/loan-guarantors"),
  },
  {
    path: "/loans/:id",
    name: "loan-details",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/loans/details"),
  },
  {
    path: "/loan-lenders",
    name: "loan-lenders",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/loan-lenders"),
  },
  {
    path: "/loan-borrowers",
    name: "loan-borrowers",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/loan-borrowers"),
  },
  {
    path: "/wallets",
    name: "wallets",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/wallets/wallets"),
  },
  {
    path: "/wallets/:id",
    name: "wallet-details",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/wallets/details"),
  },
  {
    path: "/deposits",
    name: "deposits",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/deposits/deposits"),
  },
  {
    path: "/deposits/create",
    name: "deposits-create",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/deposits/create"),
  },
  {
    path: "/withdrawals",
    name: "withdrawals",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/withdrawals/withdrawals"),
  },
  {
    path: "/withdrawals/create",
    name: "withdrawals-create",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/withdrawals/create"),
  },
  {
    path: "/wallet-transfers",
    name: "wallet-transfers",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/wallet-transfers"),
  },

  {
    path: "/transactions",
    name: "transactions",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/transactions/transactions"),
  },
  {
    path: "/transactions/reports",
    name: "transaction-reports",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/transactions/reports"),
  },
  {
    path: "/accounts",
    name: "accounts",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/accounts/accounts"),
  },
  {
    path: "/reconciliations",
    name: "reconciliations",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/reconciliations"),
  },
  {
    path: "/account-statement",
    name: "account-statement",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/account_statement/deposits"),
  },
  {
    path: "/account-statement/goal-withdraw-statement",
    name: "goal-withdraw-statement",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/account_statement/goal-withdrawals"),
  },
  {
    path: "/account-statement/:type/:date",
    name: "account-statement-details",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/account_statement/deposits/details"),
  },
  {
    path: "/account-statement/goal-withdraw-statement/:type/:date",
    name: "account-statement-details",
    meta: { authRequired: true },
    component: () =>
      import("@/pages/admin/account_statement/goal-withdrawals/details"),
  },
  {
    path: "/account-statement/revenues",
    name: "revenues",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/account_statement/revenues"),
  },
  {
    path: "/account-statement/revenues/:type/:date",
    name: "account-revenues-details",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/account_statement/revenues/details"),
  },
  {
    path: "/account-statement/utilities",
    name: "utilities",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/account_statement/utilities"),
  },
  {
    path: "/account-statement/utilities/:type/:date",
    name: "account-utilities-details",
    meta: { authRequired: true },
    component: () =>
      import("@/pages/admin/account_statement/utilities/details"),
  },
  {
    path: "/account-statement/wallet-withdrawal",
    name: "wallet-withdrawal",
    meta: { authRequired: true },
    component: () =>
      import("@/pages/admin/account_statement/wallet-withdrawal"),
  },
  {
    path: "/account-statement/wallet-withdrawal/:date",
    name: "wallet-withdrawal-details",
    meta: { authRequired: true },
    component: () =>
      import("@/pages/admin/account_statement/wallet-withdrawal/details"),
  },
  {
    path: "/reconciliations/create",
    name: "reconciliations-create",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/reconciliations/form"),
  },
  {
    path: "/reconciliations/upload",
    name: "reconciliations-upload",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/reconciliations/upload"),
  },
  {
    path: "/reconciliations/details/:id?",
    name: "reconciliation-details",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/reconciliations/details"),
  },

  {
    path: "/utility",
    name: "utility",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/utility"),
  },
  {
    path: "/accounts/:id",
    name: "account-details",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/accounts/details"),
  },
  {
    path: "/settings/general",
    name: "general-settings",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/settings/general"),
  },
  {
    path: "/settings/accounts",
    name: "account-settings",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/settings/accounts"),
  },
  {
    path: "/settings/fees",
    name: "fee-settings",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/settings/fees"),
  },
  {
    path: "/settings/commissions",
    name: "commission-settings",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/settings/commissions"),
  },
  {
    path: "/settings/agent/commissions",
    name: "commission-agent-settings",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/settings/agent_commissions"),
  },
  {
    path: "/settings/srt",
    name: "srt-settings",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/settings/srt/index.vue"),
  },
  {
    path: "/settings/loan-limit/constants",
    name: "loan-limit-constants-settings",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/settings/loan_limit/index.vue"),
  },
  {
    path: "/lotteries",
    name: "lotteries",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/lotteries/lotteries"),
  },
  {
    path: "/lotteries/types",
    name: "lotteries-types",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/lotteries/type"),
  },
  {
    path: "/lotteries/verification",
    name: "lotteries-verification",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/lotteries/verifications"),
  },
  {
    path: "/lotteries/withdrawals",
    name: "lotteries-withdrawal",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/lotteries/withdrawals"),
  },
  {
    path: "/lotteries/:id",
    name: "lottery-details",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/lotteries/details"),
  },
  {
    path: "/offers",
    name: "offers",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/offers/offers"),
  },
  {
    path: "/offers/:id",
    name: "offer-details",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/offers/details"),
  },
  {
    path: "/offers/purchases/create",
    name: "offers-purchases-create",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/offers/purchases/create"),
  },
  {
    path: "/overdue-bills",
    name: "overdue-bills",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/over-due"),
  },
  {
    path: "/points",
    name: "points",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/points"),
  },
  {
    path: "/points/transactions/create",
    name: "points-transactions-create",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/points/transactions/create"),
  },
  {
    path: "/promotions",
    name: "promotions",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/promotions/promotions"),
  },

  {
    path: "/budgets",
    name: "budgets",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/budgets"),
  },
  {
    path: "/transaction-sms",
    name: "transaction-sms",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/transaction-sms"),
  },

  {
    path: "/admins",
    name: "admins",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/users/admins"),
  },
  {
    path: "/admins/groups",
    name: "admin-groups",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/users/groups"),
  },
  {
    path: "/admins/groups/:id",
    name: "admin-groups-details",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/users/groups/details"),
  },
  {
    path: "/admin/group/create",
    name: "admin-groups-create",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/users/groups/new"),
  },
  {
    path: "/referals",
    name: "referals",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/referals"),
  },
  {
    path: "/referals/requests",
    name: "referals-requests",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/referals/requests"),
  },
  {
    path: "/referals/users/:id",
    name: "referred-users",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/referals/referred/referred_users"),
  },
  {
    path: "/permissions",
    name: "permissions",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/users/permissions"),
  },
  {
    path: "/audit-logs",
    name: "audit-logs",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/users/audit-logs"),
  },

  {
    path: "/notifications",
    name: "notifications",
    meta: { authRequired: true },
    component: () => import("@/pages/common/notifications"),
  },
  {
    path: "/notifications/create",
    name: "notifications-create",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/notifications/create"),
  },

  {
    path: "/notifications/bulk/create",
    name: "notifications-bulk-create",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/notifications/upload"),
  },

  {
    path: "/notifications/topics",
    name: "notifications-topics",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/notification-topics/index"),
  },

  {
    path: "/notifications/topics/create",
    name: "notifications-topics-create",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/notification-topics/create"),
  },

  {
    path: "/notifications/topics/details/:id",
    name: "notifications-topics-details",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/notification-topics/details"),
  },

  {
    path: "/rates/exchange",
    name: "rates-exchange",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/rates/exchange"),
  },

  {
    path: "/rates/mpesa",
    name: "rates-mpesa",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/rates/mpesa"),
  },

  {
    path: "/integrations",
    name: "integrations",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/integrations"),
  },

  {
    path: "/messages/bulk",
    name: "bulk-messages",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/bulksms"),
  },

  {
    path: "/message/bulk/create",
    name: "bulk-create",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/bulksms/create"),
  },

  {
    path: "/message/bulk/upload",
    name: "bulk-upload",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/bulksms/upload"),
  },

  {
    path: "/messages/custom",
    name: "sms-custom",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/bulkcustom/"),
  },

  {
    path: "/messages/notifications",
    name: "message-notifications",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/user-notifications"),
  },

  {
    path: "/messages/admin/notifications",
    name: "message-admin-notifications",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/admin-notifications"),
  },

  {
    path: "/site/contact",
    name: "site-contact",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/staticsite/contactus"),
  },

  {
    path: "/site/faq",
    name: "site-faq",
    meta: { authRequired: true },
    component: () => import("@/pages/admin/staticsite/faq"),
  },

  {
    path: "/login",
    name: "login",
    component: () => import("@/pages/guest/home"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        if (store.getters["auth/loggedIn"]) next({ name: "home" });
        else next();
      },
    },
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () => import("@/pages/guest/forgot-password"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        if (store.getters["auth/loggedIn"]) next({ name: "home" });
        else next();
      },
    },
  },
  {
    path: "/reset-password/:token",
    name: "reset-password",
    component: () => import("@/pages/guest/reset-password"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        if (store.getters["auth/loggedIn"]) next({ name: "home" });
        else next();
      },
    },
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        store.dispatch("auth/removeCurrentUser");
        next({ name: "login" });
      },
    },
  },

  // ! Bot Routes
  {
    path: "/bot/login",
    name: "bot-login",
    meta: { authRequired: true },
    component: () => import("@/pages/bot/login"),
  },

  {
    path: "/bot",
    name: "bot-dashboard",
    meta: { authRequired: true },
    component: () => import("@/pages/bot/dashboard"),
  },

  {
    path: "/bot/tags",
    name: "bot-tags",
    meta: { authRequired: true },
    component: () => import("@/pages/bot/tags"),
  },

  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: "*",
    name: "404",
    // redirect: { name: 'home' },
  },
];
