import { tokenHeaders } from "../../services/helpers";
import Axios from "axios";

const ADMIN_URL = process.env.VUE_APP_ADMIN_URL;

const state = {
  admin_loading: false,
  admin_error: false,
  admin_message: "",

  admin_users: [],
  admin_page: 1,
  admin_last_page: 1,

  admin_groups: [],
  group_page: 1,
  group_last_page: 1,

  group_details: {},
  permissions_schema: {},
};

const getters = {
  admin_user_info: (state) => ({
    users: state.admin_users,
    current_page: state.admin_page,
    last_page: state.admin_last_page,
  }),
  admin_group_info: (state) => ({
    groups: state.admin_groups,
    current_page: state.group_page,
    last_page: state.group_last_page,
  }),
  admin_group_detail: (state) => ({ details: state.group_details }),
  permissions_schema: (state) => ({ permissions: state.permissions_schema }),
};

const actions = {
  generateCMAGoalTypesReports: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "generate-cma/goal-types";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const data = response.data;
      console.log("object", data);

      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false },
        { root: true }
      );
    } catch (error) {
      console.log("error", error);
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  generateCMAGoalReports: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "generate-cma/goals";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const data = response.data;
      console.log("object", data);

      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false },
        { root: true }
      );
    } catch (error) {
      console.log("error", error);
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchAdminUsers: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "users/view?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_ADMIN_USERS", { data, last_page, current_page: params.page });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  createAdminUser: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "users/create";
      const response = await Axios.post(url, form, headers);
      const { message, data } = response.data;

      commit("ADD_ADMIN_USER", data);
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  updateAdminStatus: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "users/status/update";
      const response = await Axios.patch(url, form, headers);
      const { message, data } = response.data;

      commit("UPDATE_ADMIN_USER", data);
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchAdminGroups: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "groups/view?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_ADMIN_GROUPS", {
        data,
        last_page,
        current_page: params.page,
      });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchAdminGroupDetails: async ({ commit, rootState }, group_id) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + `groups/details?group_id=${group_id}`;

      const response = await Axios.get(url, headers);
      const { data } = response.data;

      commit("SET_ADMIN_GROUP_DETAIL", data);
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchPermissionsSchema: async ({ commit, rootState }) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "groups/schema";

      const response = await Axios.get(url, headers);
      const { data } = response.data;

      commit("SET_PERMISSION_SCHEMA", data);
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  createAdminGroup: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "groups/create";
      const response = await Axios.post(url, form, headers);
      const { message } = response.data;

      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  deleteAdminGroup: async ({ commit, rootState }, group_id) => {
    // groups/delete
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + `groups/delete?group_id=${group_id}`;

      const response = await Axios.delete(url, headers);
      const { message } = response.data;

      commit("DELETE_ADMIN_GROUP", { id: group_id });
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
};

const mutations = {
  SET_ADMIN_USERS: (state, newValue) => {
    state.admin_users = newValue.data;
    state.admin_page = newValue.current_page;
    state.admin_last_page = newValue.last_page;
  },
  ADD_ADMIN_USER: (state, newValue) => {
    let new_array = [newValue, ...state.admin_users];
    state.admin_users = new_array;
  },
  UPDATE_ADMIN_USER: (state, newValue) => {
    let new_array = state.admin_users.map((item) => {
      if (item.id == newValue.id) {
        item = newValue;
      }
      return item;
    });
    state.admin_users = new_array;
  },
  SET_ADMIN_GROUPS: (state, newValue) => {
    state.admin_groups = newValue.data;
    state.group_page = newValue.current_page;
    state.group_last_page = newValue.last_page;
  },
  SET_ADMIN_GROUP_DETAIL: (state, newValue) => {
    state.group_details = newValue;
  },
  DELETE_ADMIN_GROUP: (state, newValue) => {
    let new_array = state.admin_groups.filter((item) => item.id != newValue.id);
    state.admin_groups = new_array;
  },
  SET_PERMISSION_SCHEMA: (state, newValue) => {
    state.permissions_schema = newValue;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
