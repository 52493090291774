<template>
    <div>
        <input ref="bulkUpload" @change="onFileChanged($event)" type="file" accept="*" class="d-none" />

        <div v-if="uploaded_file" class="btn-group">
            <button type="button" class="btn btn-rounded btn-primary">{{ 'Selected file: ' + uploaded_file.name }}</button>
            <button type="button" class="btn btn-rounded btn-danger" @click.prevent="resetFile()" title="Remove"><i class="fa fa-times"></i></button>
        </div>
        <button type="button" v-else class="btn btn-rounded btn-primary" @click.prevent="$refs.bulkUpload.click()">Select File</button>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                uploaded_file: null
            }
        },
        watch: {
            'uploaded_file': function(value) {
                this.$emit('uploaded', this.uploaded_file)
                
                if(value == null)
                    this.$refs.bulkUpload.value = ''
            }
        },
        methods: {
            onFileChanged(event) {
                if(event.target.files.length > 0)
                    this.uploaded_file = event.target.files[0];
            },
            resetFile() {
                this.uploaded_file = null
            },
            
        },
        props: [
            'form', 'error'
        ]
    }
</script>

<style>

</style>