import { tokenHeaders } from "../../services/helpers";
import Axios from "axios";

const ADMIN_URL = process.env.VUE_APP_ADMIN_URL;
const CLIENT_URL = process.env.VUE_APP_CLIENT_URL;
const state = {
  groups: [],
  groups_page: 1,
  groups_last_page: 1,

  registartion_groups: [],
  registartion_groups_page: 1,
  registartion_groups_last_page: 1,

  validation_groups: [],
  validation_groups_page: 1,
  validation_groups_last_page: 1,

  withdrawal_groups: [],
  withdrawal_groups_page: 1,
  withdrawal_groups_last_page: 1,
};

const getters = {
  groups_info: state => ({ groups: state.groups, current_page: state.groups_page, last_page: state.groups_last_page }),
  registration_info: state => ({ registrations: state.registartion_groups, current_page: state.registartion_groups_page, last_page: state.registartion_groups_last_page }),
  validation_info: state => ({ validations: state.validation_groups, current_page: state.validation_groups_page, last_page: state.validation_groups_last_page }),
  withdrawals_info: state => ({ withdrawals: state.withdrawal_groups, current_page: state.withdrawal_groups_page, last_page: state.withdrawal_groups_last_page }),
};

const actions = {
  fetchGroups: async ({ commit, rootState }, params) => {
    commit("SET_LOADING", { loading: true, message: "", error: false }, { root: true });
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "clients/groups/view?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;
      commit("SET_GROUPS", { data, last_page, current_page: params.page });
      commit("SET_LOADING", { loading: false, message: "", error: false, created: true }, { root: true });
    } catch (error) {
      console.log(error);
      const message = error.response.data?.message ?? "";
      commit("SET_LOADING", { loading: false, message: message, error: true }, { root: true });
    }
  },
  fetchGroupsPaginated: async ({ commit, rootState }, params) => {
    commit("SET_LOADING", { loading: true, message: "", error: false }, { root: true });
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "clients/groups/view?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_GROUPS_PAGINATED", { data, last_page, current_page: params.page });
      commit("SET_LOADING", { loading: false, message: "", error: false, created: true }, { root: true });
    } catch (error) {
      console.log(error);
      const message = error.response.data?.message ?? "";
      commit("SET_LOADING", { loading: false, message: message, error: true }, { root: true });
    }
  },
  createGroup: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "clients/groups/create";
      const response = await Axios.post(url, form, headers);
      const { message, data } = response.data;
      commit("ADD_GROUP", data);
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  editGroup: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "clients/groups/update";
      const response = await Axios.post(url, form, headers);
      const { message, data } = response.data;

      commit("UPDATE_GROUP", data);
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response?.data?.message;
      commit("SET_LOADING", { loading: false, message: message, error: true }, { root: true });
    }
  },
  updateGroupStatus: async ({ commit, rootState }, form) => {
    commit("SET_LOADING", { loading: false, message: "", error: false }, { root: true });
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "clients/groups/status";
      const response = await Axios.post(url, form, headers);
      const { message, data } = response.data;
      commit("UPDATE_GROUP", data);
      commit("SET_LOADING", { loading: false, message: message, error: false, created: true }, { root: true });
    } catch (error) {
      const message = error.response?.data?.message;
      commit("SET_LOADING", { loading: false, message: message, error: true }, { root: true });
    }
  },

  // Registration
  fetchRegistrations: async ({ commit, rootState }, params) => {
    commit("SET_LOADING", { loading: true, message: "", error: false }, { root: true });
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "group/registration/requests?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;
      commit("SET_GROUP_REGISTRATIONS", { data, last_page, current_page: params.page });
      commit("SET_LOADING", { loading: false, message: "", error: false, created: true }, { root: true });
    } catch (error) {
      console.log(error);
      const message = error.response.data?.message ?? "";
      commit("SET_LOADING", { loading: false, message: message, error: true }, { root: true });
    }
  },


  updateGroupRegistrationConfirmed: async ({ commit, rootState }, form) => {
    commit("SET_LOADING", { loading: false, message: "", error: false }, { root: true });
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "group/registration/confirm";
      const response = await Axios.post(url, form, headers);
      const { message, data } = response.data;
      commit("UPDATE_GROUP_REGISTRATION", data);
      commit("SET_LOADING", { loading: false, message: message, error: false, created: true }, { root: true });
    } catch (error) {
      const message = error.response?.data?.message;
      commit("SET_LOADING", { loading: false, message: message, error: true }, { root: true });
    }
  },

  updateGroupValidationConfirmed: async ({ commit, rootState }, form) => {
    commit("SET_LOADING", { loading: false, message: "", error: false }, { root: true });
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "group/validation/confirm";
      const response = await Axios.post(url, form, headers);
      const { message, data } = response.data;
      commit("UPDATE_GROUP_VALIDATION", data);
      commit("SET_LOADING", { loading: false, message: message, error: false, created: true }, { root: true });
    } catch (error) {
      const message = error.response?.data?.message;
      commit("SET_LOADING", { loading: false, message: message, error: true }, { root: true });
    }
  },

  fetchValidations: async ({ commit, rootState }, params) => {
    commit("SET_LOADING", { loading: true, message: "", error: false }, { root: true });
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "group/validation/requests?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;
      commit("SET_GROUP_VALIDATIONS", { data, last_page, current_page: params.page });
      commit("SET_LOADING", { loading: false, message: "", error: false, created: true }, { root: true });
    } catch (error) {
      console.log(error);
      const message = error.response.data?.message ?? "";
      commit("SET_LOADING", { loading: false, message: message, error: true }, { root: true });
    }
  },
  fetchWithdrawals: async ({ commit, rootState }, params) => {
    commit("SET_LOADING", { loading: true, message: "", error: false }, { root: true });
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "group/withdrawal/requests?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;
      commit("SET_GROUP_WITHDRAWALS", { data, last_page, current_page: params.page });
      commit("SET_LOADING", { loading: false, message: "", error: false, created: true }, { root: true });
    } catch (error) {
      console.log(error);
      const message = error.response.data?.message ?? "";
      commit("SET_LOADING", { loading: false, message: message, error: true }, { root: true });
    }
  },
  updateGroupWithdrawalConfirmed: async ({ commit, rootState }, form) => {
    commit("SET_LOADING", { loading: false, message: "", error: false }, { root: true });
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "group/withdrawal/confirm";
      const response = await Axios.post(url, form, headers);
      const { message, data } = response.data;
      commit("UPDATE_GROUP_WITHDRAWAL", data);
      commit("SET_LOADING", { loading: false, message: message, error: false, created: true }, { root: true });
    } catch (error) {
      const message = error.response?.data?.message;
      commit("SET_LOADING", { loading: false, message: message, error: true }, { root: true });
    }
  },
};

const mutations = {
  SET_GROUPS: (state, newValue) => {
    (state.groups = newValue.data), (state.groups_page = newValue.current_page);
    state.groups_last_page = newValue.last_page;
  },
  SET_GROUP_REGISTRATIONS: (state, newValue) => {
    state.registartion_groups = newValue.data;
    state.registartion_groups_page = newValue.current_page;
    state.registartion_groups_last_page = newValue.last_page;
  },
  SET_GROUP_VALIDATIONS: (state, newValue) => {
    state.validation_groups = newValue.data;
    state.validation_groups_page = newValue.current_page;
    state.validation_groups_last_page = newValue.last_page;
  },
  SET_GROUPS_PAGINATED: (state, newValue) => {
    (state.groups = [...state.groups, newValue.data]), (state.groups_page = newValue.current_page);
    state.groups_last_page = newValue.last_page;
  },
  SET_GROUP_WITHDRAWALS: (state, newValue) => {
    state.withdrawal_groups = newValue.data;
    state.withdrawal_groups_page = newValue.current_page;
    state.withdrawal_groups_last_page = newValue.last_page;
  },
  UPDATE_GROUP: (state, newValue) => {
    let newArray = state.groups.map(item => {
      if (item.id == newValue.id) {
        item = newValue;
      }
      return item;
    });
    state.groups = newArray;
  },
  UPDATE_GROUP_REGISTRATION: (state, newValue) => {

    let newArray = state.registartion_groups.map(item => {
      if (item.id == newValue.id) {
        item = newValue;
      }
      return item;
    });
    state.registartion_groups = newArray;
  },
  UPDATE_GROUP_VALIDATION: (state, newValue) => {

    let newArray = state.validation_groups.map(item => {
      if (item.id == newValue.id) {
        item = newValue;
      }
      return item;
    });
    state.validation_groups = newArray;
  },
  UPDATE_GROUP_WITHDRAWAL: (state, newValue) => {

    let newArray = state.withdrawal_groups.map(item => {
      if (item.id == newValue.id) {
        item = newValue;
      }
      return item;
    });
    state.withdrawal_groups = newArray;
  },
  ADD_GROUP: (state, newValue) => {
    state.groups = [newValue, ...state.groups];
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
