import { tokenHeaders } from "../../services/helpers";
import Axios from "axios";

const ADMIN_URL = process.env.VUE_APP_ADMIN_URL;

export const state = {
  referals: [],
  referals_page: 1,
  referals_last_page: 1,
  clients_analytics: {},

  referred_users: [],
  referred_users_page: 1,
  referred_users_last_page: 1,

  referals_schedules: [],
  referals_schedules_page: 1,
  referals_schedules_last_page: 1,

  referals_requests: [],
  referals_requests_page: 1,
  referals_requests_last_page: 1,
};

const getters = {
  referals_info: (state) => ({
    referals: state.referals,
    current_page: state.referals_page,
    last_page: state.referals_last_page,
  }),
  referals_analytics: (state) => state.clients_analytics,
  referred_users_info: (state) => ({
    users: state.referred_users,
    current_page: state.referred_users_page,
    last_page: state.referred_users_last_page,
  }),
  referals_requests_info: (state) => ({
    requests: state.referals_requests,
    current_page: state.referals_requests_page,
    last_page: state.referals_requests_last_page,
  }),
};

export const mutations = {
  SET_REFERALS: (state, newValue) => {
    state.referals = newValue.data;
    state.referals_page = newValue.current_page;
    state.referals_last_page = newValue.last_page;
  },
  SET_CLIENTS_ANALYTICS: (state, newValue) => {
    state.clients_analytics = newValue || {};
  },
  SET_REFERRED_USERS: (state, newValue) => {
    state.referred_users = newValue.data;
    state.referred_users_page = newValue.current_page;
    state.referred_users_last_page = newValue.last_page;
  },
  ADD_ADMIN_REFERAL: (state, newValue) => {
    state.referals_schedules = [newValue, ...state.referals_schedules];
  },
  SET_REFERAL_REQUEST: (state, newValue) => {
    state.referals_requests = newValue.data;
    state.referals_requests_page = newValue.current_page;
    state.referals_requests_last_page = newValue.last_page;
  },
  UPDATE_REFERAL_REQUEST: (state, newValue) => {
    let newArray = state.referals_requests.map((item) => {
      if (item.id == newValue.id) {
        item = newValue;
      }
      return item;
    });
    state.referals_requests = newArray;
  },
};

export const actions = {
  fetchReferals: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "referals/view?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_REFERALS", { data, last_page, current_page: params.page });
      commit(
        "SET_LOADING",
        {
          referals_pageloading: false,
          message: "",
          error: false,
          created: true,
        },
        { root: true }
      );
    } catch (error) {
      const message = error.response?.data;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchReferres: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "referals/referred_users/view?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_REFERRED_USERS", {
        data,
        last_page,
        current_page: params.page,
      });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchReferresAnalytics: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "referals/referred_users/analytics?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data } = response.data;

      commit("SET_CLIENTS_ANALYTICS", data);
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  createAdminReferal: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "referals/refer_schedule/create";
      const response = await Axios.post(url, form, headers);
      const { message, data } = response.data;

      commit("ADD_ADMIN_REFERAL", data);
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchReferalsRequest: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "referals/request/view?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;
      commit("SET_REFERAL_REQUEST", {
        data,
        last_page,
        current_page: params.page,
      });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  updateReferalRequest: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: false, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "referals/refer_schedule/update_status";
      const response = await Axios.post(url, form, headers);
      const { message, data } = response.data;
      commit("UPDATE_REFERAL_REQUEST", data);
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response?.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
