import FormError from '@/components/form-error.vue'
import FormModal from '@/components/form-modal'
import TableEditButton from '@/components/table-edit-button'
import TableDeleteButton from '@/components/table-delete-button'
import TableButtonLink from '@/components/table-button-link'
import Avatar from '@/components/avatar'
import ExportButton from '@/components/export-button'
import FileUpload from '@/components/file-upload'

/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
  install (Vue) {
    Vue.component('form-error', FormError)
    Vue.component('form-modal', FormModal)
    Vue.component('table-edit-button', TableEditButton)
    Vue.component('table-delete-button', TableDeleteButton)
    Vue.component('table-button-link', TableButtonLink)
    Vue.component('Avatar', Avatar)
    Vue.component('ExportButton', ExportButton)
    Vue.component('FileUpload', FileUpload)
  }
}

export default GlobalComponents
