import { tokenHeaders, tokenMultiPartHeaders } from "../../services/helpers";
import Axios from "axios";

const ADMIN_URL = process.env.VUE_APP_ADMIN_URL;

const state = {
  lotteries: [],
  lotteries_page: 1,
  lotteries_last_page: 1,

  lottery_types: [],
  lottery_types_page: 1,
  lottery_types_last_page: 1,

  lottery_verifications: [],
  lottery_verifications_page: 1,
  lottery_verifications_last_page: 1,

  lottery_withdrawals: [],
  lottery_withdrawals_page: 1,
  lottery_withdrawals_last_page: 1,
};

const getters = {
  lotteries_info: (state) => ({
    lotteries: state.lotteries,
    current_page: state.lotteries_page,
    last_page: state.lotteries_last_page,
  }),
  lottery_types_info: (state) => ({
    lottery_types: state.lottery_types,
    current_page: state.lottery_types_page,
    last_page: state.lottery_types_last_page,
  }),
  lottery_verifications_info: (state) => ({
    lottery_verifications: state.lottery_verifications,
    current_page: state.lottery_verifications_page,
    last_page: state.lottery_verifications_last_page,
  }),
  lottery_withdrawal_info: (state) => ({
    withdrawals: state.lottery_withdrawals,
    current_page: state.lottery_withdrawals_page,
    last_page: state.lottery_withdrawals_last_page,
  }),
};

const actions = {
  fetchLotteryTypes: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "lottery/types?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_LOTTERY_TYPES", {
        data,
        last_page,
        current_page: params.page,
      });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  createLotteryTypes: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );

    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "lottery/types/create";
      const response = await Axios.post(url, form, headers);
      const { message, data } = response.data;

      commit("ADD_LOTTERY_TYPE", data);
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  updateLotteryType: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );

    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "lottery/types/update";
      const response = await Axios.post(url, form, headers);
      const { message, data } = response.data;

      commit("UPDATE_LOTTERY_TYPE", data);
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  fetchLotteryVerification: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "lottery/verification?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_LOTTERY_VERIFICATION", {
        data,
        last_page,
        current_page: params.page,
      });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  updateLotteryVerificationStatus: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );

    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "lottery/verification/status";
      const response = await Axios.post(url, form, headers);
      const { message, data } = response.data;

      commit("UPDATE_LOTTERY_VERIFICATION", data);
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  fetchLotteryWithdrawals: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "lottery/withdrawal/view?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_LOTTERY_WITHDRAWALS", {
        data,
        last_page,
        current_page: params.page,
      });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  updateLotteryWithdrawalStatus: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );

    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "lottery/withdrawal/update";
      const response = await Axios.post(url, form, headers);
      const { message, data } = response.data;

      commit("UPDATE_LOTTERY_WITHDRAWAL", data);
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
};

const mutations = {
  SET_LOTTERY_TYPES: (state, newValue) => {
    state.lottery_types = newValue.data;
    state.lottery_types_page = newValue.current_page;
    state.lottery_types_last_page = newValue.last_page;
  },
  ADD_LOTTERY_TYPE: (state, newValue) => {
    state.lottery_types = [newValue, ...state.lottery_types];
  },
  UPDATE_LOTTERY_TYPE: (state, newValue) => {
    state.lottery_types = state.lottery_types.map((item) => {
      if (item.id == newValue.id) item = newValue;
      return item;
    });
  },
  SET_LOTTERY_VERIFICATION: (state, newValue) => {
    state.lottery_verifications = newValue.data;
    state.lottery_verifications_page = newValue.current_page;
    state.lottery_verifications_last_page = newValue.last_page;
  },
  UPDATE_LOTTERY_VERIFICATION: (state, newValue) => {
    state.lottery_verifications = state.lottery_verifications.map((item) => {
      if (item.id == newValue.id) item = newValue;
      return item;
    });
  },
  SET_LOTTERY_WITHDRAWALS: (state, newValue) => {
    state.lottery_withdrawals = newValue.data;
    state.lottery_withdrawals_page = newValue.current_page;
    state.lottery_withdrawals_last_page = newValue.last_page;
  },
  UPDATE_LOTTERY_WITHDRAWAL: (state, newValue) => {
    state.lottery_withdrawals = state.lottery_withdrawals.map((item) => {
      if (item.id == newValue.id) item = newValue;

      return item;
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
