import { tokenHeaders } from "../../services/helpers";
import Axios from "axios";

const ADMIN_URL = process.env.VUE_APP_ADMIN_URL;

const state = {
  black_list_clients: [],
  black_list_page: 1,
  black_list_last_page: 1,

  black_list_details: {},

  black_list_transactions: [],
  black_list_transaction_page: 1,
  black_list_transaction_last_page: 1,
};

const getters = {
  black_list_info: (state) => ({
    clients: state.black_list_clients,
    current_page: state.black_list_page,
    last_page: state.black_list_last_page,
  }),

  black_list_transaction_info: (state) => ({
    transactions: state.black_list_transactions,
    current_page: state.black_list_transaction_page,
    last_page: state.black_list_transaction_last_page,
  }),

  black_list_details: (state) => state.black_list_details,
};

const actions = {
  fetchBlackListClients: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "blacklist/list?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }

      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_BLACKLIST_CLIENTS", {
        data,
        last_page,
        current_page: params.page,
      });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      const message = error.response.data;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  fetchBlackListClientsDetails: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "blacklist/retrieve?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }

      const response = await Axios.get(url, headers);
      const { data } = response.data;

      commit("SET_BLACKLIST_DETAILS", data);
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      const message = error.response.data;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  createBlackListClient: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "blacklist/create";
      const response = await Axios.post(url, form, headers);
      const { message, data } = response.data;

      commit("UPDATE_USER", data, { root: true });
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  removeBlackListClient: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "blacklist/remove";
      const response = await Axios.post(url, form, headers);
      const { message, data } = response.data;

      commit("UPDATE_BLACKLIST_CLIENT", form);
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  fetchBlackListTransactions: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "blacklist/transactions/list?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }

      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_BLACKLIST_TRANSACTIONS", {
        data,
        last_page,
        current_page: params.page,
      });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      const message = error.response.data;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  removeBlackListTransaction: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "blacklist/transactions/remove";
      const response = await Axios.post(url, form, headers);
      const { message, data } = response.data;

      commit("UPDATE_USER", data, { root: true });
      commit("REMOVE_BLACKLIST_TRANSACTION", form);
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
};

const mutations = {
  SET_BLACKLIST_CLIENTS: (state, newValue) => {
    state.black_list_clients = newValue.data;
    state.black_list_page = newValue.current_page;
    state.black_list_last_page = newValue.last_page;
  },
  SET_BLACKLIST_DETAILS: (state, newValue) => {
    state.black_list_details = newValue;
  },
  UPDATE_BLACKLIST_CLIENT: (state, newValue) => {
    let newArray = state.black_list_clients.filter(
      (item) => item.id != newValue.id
    );

    state.black_list_clients = newArray;
  },

  SET_BLACKLIST_TRANSACTIONS: (state, newValue) => {
    state.black_list_transactions = newValue.data;
    state.black_list_transaction_page = newValue.current_page;
    state.black_list_transaction_last_page = newValue.last_page;
  },

  REMOVE_BLACKLIST_TRANSACTION: (state, newValue) => {
    let newArray = state.black_list_transactions.filter(
      (item) => item.id != newValue.id
    );

    state.black_list_transactions = newArray;
    state.black_list_details = {};
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
