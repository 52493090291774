<template>
  <b-dropdown variant="_">
    <template v-slot:button-content>
      <button class="btn btn-primary btn-rounded"><i class="mdi mdi-download"></i> {{ `Export` }}</button>
    </template>
    <b-dropdown-item-button @click="download('PDF')">PDF</b-dropdown-item-button>
    <b-dropdown-item-button @click="download('CSV')">CSV</b-dropdown-item-button>
  </b-dropdown>
</template>

<script>
export default {
  methods: {
    download(format) {
      this.loadingTable = true;
      const formatUrl = this.url.includes("?") ? `&export_format=${format}` : `?export_format=${format}`;
      this.$http
        .get(this.url + formatUrl)
        .then(response => {
          this.$toastSuccess(response.data.message);
          this.loadingTable = false;
          const downloadLink = document.createElement("a");
          downloadLink.setAttribute("href", response.data.data);
          downloadLink.setAttribute("target", "_blank");
          downloadLink.setAttribute("download", "");
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        })
        .catch(error => {
          this.loadingTable = false;
          this.$toastError(error.message);
        });
    },
  },
  props: {
    url: String,
  },
};
</script>

<style></style>
