<template>
    <a v-b-tooltip.hover @click="$emit('click')" title="Edit" href="#" class="text-primary mr-2">
        <i class="mdi mdi-pencil font-size-18"></i>
    </a>
</template>

<script>
export default {

}
</script>

<style>

</style>