const state = {
    loading: false,
    error: false,
    message: '',
    created: false
}

const getters = {
    loadingInfo: (state) => ({loading: state.loading, error: state.error, message: state.message, created: state.created})
}

const actions = {
    
}

const mutations = {
    SET_LOADING: (state, newValue) => {
        state.loading = newValue.loading
        state.error = newValue.error
        state.message = newValue.message
        state.created = newValue.created ?? false
    }
}

export default {
    state,
    getters,
    actions,
    mutations
};




// 
// import {tokenHeaders} from '../../services/helpers'
// import Axios from 'axios';


// const ADMIN_URL = process.env.VUE_APP_ADMIN_URL
// const CLIENT_URL = process.env.VUE_APP_CLIENT_URL
// const state = {
//     goals: [],
//     goals_page: 1,
//     goals_last_page: 1,

//     goal_details: {},

//     goal_transactions: [],
//     goal_transactions_page: 1,
//     goal_transactions_last_page: 1,

//     investments: [],
//     investment_page: 1,
//     investment_last_page: 1,

//     savings: [],
//     savings_page: 1,
//     savings_last_page: 1,

//     goal_withdrawals: [],
//     goal_withdrawal_page: 1,
//     goal_withdrawal_last_page: 1,
// }

// const getters = {
//     goals_info: (state) => ({goals: state.goals, current_page: state.goals_page, last_page: state.goals_last_page}),
//     goal_details: (state) => state.goal_details,
//     goal_transactions: (state) => ({transactions: state.goal_transactions, current_page: state.goal_transactions_page, last_page: state.goal_transactions_last_page}),
//     goal_withdrawals: (state) => ({withdrawals: state.goal_withdrawals, current_page: state.goal_withdrawal_page, last_page: state.goal_withdrawal_last_page}),
//     investment_info: (state) => ({investments: state.investments, current_page: state.investment_page, last_page: state.investment_last_page}),
//     saving_info: (state) => ({savings: state.savings, current_page: state.savings_page, last_page: state.savings_last_page}),
// }

// const actions = {
//     fetchGoals: async ({commit, rootState}, params) => {
//         commit('SET_LOADING', {loading: true, message: '', error: false}, { root: true }); 
//         try {
//             let ip_address = rootState.auth.ip_address
//             let token = rootState.auth.auth_token
//             const headers = tokenHeaders({ip_address, token})
            
//             let url = ADMIN_URL + 'goals/view?'
//             for (let key in params) {
//                 if (params[key]) {
//                   url += `&${key}=${params[key]}`
//                 }
//             }
//             const response = await Axios.get(url, headers)
//             const {data, last_page} = response.data
            
//             commit('SET_GOALS', {data, last_page, current_page: params.page})
//             commit('SET_LOADING', {loading: false, message: '', error: false, created: true}, { root: true }); 
//         } catch (error) {
//             const message = error.response.data?.message ?? ''
//             commit('SET_LOADING', {loading: false, message: message, error: true}, { root: true }); 
//         }
//     },
//     fetchGoalsPaginated: async ({commit, rootState}, params) => {
//         commit('SET_LOADING', {loading: true, message: '', error: false}, { root: true }); 
//         try {
//             let ip_address = rootState.auth.ip_address
//             let token = rootState.auth.auth_token
//             const headers = tokenHeaders({ip_address, token})
            
//             let url = ADMIN_URL + 'goals/view?'
//             for (let key in params) {
//                 if (params[key]) {
//                   url += `&${key}=${params[key]}`
//                 }
//             }
//             const response = await Axios.get(url, headers)
//             const {data, last_page} = response.data
            
//             commit('SET_GOALS_PAGINATED', {data, last_page, current_page: params.page})
//             commit('SET_LOADING', {loading: false, message: '', error: false, created: true}, { root: true }); 
//         } catch (error) {
//             const message = error.response.data?.message ?? ''
//             commit('SET_LOADING', {loading: false, message: message, error: true}, { root: true }); 
//         }
//     },
//     fetchGoalDetails: async ({commit, rootState}, params) => {
//         commit('SET_LOADING', {loading: true, message: '', error: false}, { root: true }); 
//         try {
//             let ip_address = rootState.auth.ip_address
//             let token = rootState.auth.auth_token
//             const headers = tokenHeaders({ip_address, token})

//             let url = ADMIN_URL + 'goals/retrieve?'
//             for (let key in params) {
//                 if (params[key]) {
//                   url += `&${key}=${params[key]}`
//                 }
//             }
//             const response = await Axios.get(url, headers)
//             const {data} = response.data

//             commit('SET_GOAL_DETIALS', data)
//             commit('SET_LOADING', {loading: false, message: '', error: false, created: true}, { root: true }); 
//         } catch (error) {
//             const message = error.response.data
//             commit('SET_LOADING', {loading: false, message: message, error: true}, { root: true }); 
//         }
//     },
//     fetchGoalTransacions: async ({commit, rootState}, params) => {
//         commit('SET_LOADING', {loading: true, message: '', error: false}, { root: true }); 
//         try {
//             let ip_address = rootState.auth.ip_address
//             let token = rootState.auth.auth_token
//             const headers = tokenHeaders({ip_address, token})

//             let url = ADMIN_URL + 'goals/transaction?'
//             for (let key in params) {
//                 if (params[key]) {
//                   url += `&${key}=${params[key]}`
//                 }
//             }
//             const response = await Axios.get(url, headers)
//             const {data, last_page} = response.data

//             commit('SET_GOAL_TRANSACTIONS', {data, last_page, current_page: params.page})
//             commit('SET_LOADING', {loading: false, message: '', error: false, created: true}, { root: true }); 
//         } catch (error) {
//             const message = error.response.data?.message
//             commit('SET_LOADING', {loading: false, message: message, error: true}, { root: true }); 
//         }
//     },
//     fetchInvestments: async ({commit, rootState}, params) => {
//         commit('SET_LOADING', {loading: true, message: '', error: false}, { root: true }); 
//         try {
//             let ip_address = rootState.auth.ip_address
//             let token = rootState.auth.auth_token
//             const headers = tokenHeaders({ip_address, token})

//             let url = ADMIN_URL + 'investments/view?'
//             for (let key in params) {
//                 if (params[key]) {
//                   url += `&${key}=${params[key]}`
//                 }
//             }
//             const response = await Axios.get(url, headers)
//             const {data, last_page} = response.data

//             commit('SET_INVESTMENTS', {data, last_page, current_page: params.page})
//             commit('SET_LOADING', {loading: false, message: '', error: false, created: true}, { root: true }); 
//         } catch (error) {
//             const message = error.response.data?.message
//             commit('SET_LOADING', {loading: false, message: message, error: true}, { root: true }); 
//         }
//     },
//     fetchInvestmentsPaginated: async ({ commit, rootState }, params) => {
//         commit("SET_LOADING", { loading: true, message: "", error: false }, { root: true });
//         try {
//           let ip_address = rootState.auth.ip_address;
//           let token = rootState.auth.auth_token;
//           const headers = tokenHeaders({ ip_address, token });
    
//           let url = ADMIN_URL + 'investments/view?'
//           for (let key in params) {
//             if (params[key]) {
//               url += `&${key}=${params[key]}`;
//             }
//           }
//           const response = await Axios.get(url, headers);
//           const { data, last_page } = response.data;
    
//           commit("SET_INVESTMENTS_PAGINATED", { data, last_page, current_page: params.page });
//           commit("SET_LOADING", { loading: false, message: "", error: false, created: true }, { root: true });
//         } catch (error) {
//           console.log(error);
//           const message = error.response.data?.message ?? "";
//           commit("SET_LOADING", { loading: false, message: message, error: true }, { root: true });
//         }
//     },
//     fetchSavings: async ({commit, rootState}, params) => {
//         commit('SET_LOADING', {loading: true, message: '', error: false}, { root: true }); 
//         try {
//             let ip_address = rootState.auth.ip_address
//             let token = rootState.auth.auth_token
//             const headers = tokenHeaders({ip_address, token})

//             let url = ADMIN_URL + 'admin/savings?'
//             for (let key in params) {
//                 if (params[key]) {
//                   url += `&${key}=${params[key]}`
//                 }
//             }
//             const response = await Axios.get(url, headers)
//             const {data, last_page} = response.data

//             commit('SET_SAVINGS', {data, last_page, current_page: params.page})
//             commit('SET_LOADING', {loading: false, message: '', error: false, created: true}, { root: true }); 
//         } catch (error) {
//             const message = error.response.data?.message
//             commit('SET_LOADING', {loading: false, message: message, error: true}, { root: true }); 
//         }
//     },
//     fetchSavingsPaginated: async ({ commit, rootState }, params) => {
//         commit("SET_LOADING", { loading: true, message: "", error: false }, { root: true });
//         try {
//           let ip_address = rootState.auth.ip_address;
//           let token = rootState.auth.auth_token;
//           const headers = tokenHeaders({ ip_address, token });
    
//           let url = ADMIN_URL + 'admin/savings?'
//           for (let key in params) {
//             if (params[key]) {
//               url += `&${key}=${params[key]}`;
//             }
//           }
//           const response = await Axios.get(url, headers);
//           const { data, last_page } = response.data;
    
//           commit("SET_SAVINGS_PAGINATED", { data, last_page, current_page: params.page });
//           commit("SET_LOADING", { loading: false, message: "", error: false, created: true }, { root: true });
//         } catch (error) {
//           console.log(error);
//           const message = error.response.data?.message ?? "";
//           commit("SET_LOADING", { loading: false, message: message, error: true }, { root: true });
//         }
//     },
//     updateInvestmentStatus: async ({commit, rootState}, form) => {
//         commit('SET_LOADING', {loading: true, message: '', error: false}, { root: true }); 
//         try {
//             let ip_address = rootState.auth.ip_address
//             let token = rootState.auth.auth_token
//             const headers = tokenHeaders({ip_address, token})

//             let url = ADMIN_URL + 'investments/status'
//             const response = await Axios.post(url, form, headers)
//             const {message, data} = response.data

//             commit('UPDATE_INVESTMENT', data)
//             commit('SET_LOADING', {loading: false, message: message, error: false, created: true}, { root: true }); 
//         } catch (error) {
//             const message = error.response.data?.message
//             commit('SET_LOADING', {loading: false, message: message, error: true}, { root: true }); 
//         }
//     },
//     fetchGoalWithdrawals: async ({commit, rootState}, params) => {
//         commit('SET_LOADING', {loading: true, message: '', error: false}, { root: true }); 
//         try {
//             let ip_address = rootState.auth.ip_address
//             let token = rootState.auth.auth_token
//             const headers = tokenHeaders({ip_address, token})

//             let url = ADMIN_URL + 'goals/withdrawal/view?'
//             for (let key in params) {
//                 if (params[key]) {
//                   url += `&${key}=${params[key]}`
//                 }
//             }
//             const response = await Axios.get(url, headers)
//             const {data, last_page} = response.data

//             commit('SET_GOAL_WITHDRAWALS', {data, last_page, current_page: params.page})
//             commit('SET_LOADING', {loading: false, message: '', error: false, created: true}, { root: true }); 
//         } catch (error) {
//             const message = error.response.data?.message
//             commit('SET_LOADING', {loading: false, message: message, error: true}, { root: true }); 
//         }
//     },
//     createGoal: async ({commit, rootState}, form) => {
//         commit('SET_LOADING', {loading: true, message: '', error: false}, { root: true }); 
//         try {
//             let ip_address = rootState.auth.ip_address
//             let token = rootState.auth.auth_token
//             const headers = tokenHeaders({ip_address, token})

//             let url = ADMIN_URL + 'goals/create'
//             const response = await Axios.post(url, form, headers)
//             const {message, data} = response.data

//             commit('ADD_GOAL', data)
//             commit('SET_LOADING', {loading: false, message: message, error: false, created: true}, { root: true }); 
//         } catch (error) {
//             const message = error.response.data?.message
//             commit('SET_LOADING', {loading: false, message: message, error: true}, { root: true }); 
//         }
//     },
//     createGoalWithdrawals: async ({commit, rootState}, form) => {
//         commit('SET_LOADING', {loading: true, message: '', error: false}, { root: true }); 
//         try {
//             let ip_address = rootState.auth.ip_address
//             let token = rootState.auth.auth_token
//             const headers = tokenHeaders({ip_address, token})

//             let url = ADMIN_URL + 'goals/withdrawal/create'
//             const response = await Axios.post(url, form, headers)
//             const {message, data} = response.data

//             commit('ADD_GOAL_WITHDRAWAL', data)
//             commit('SET_LOADING', {loading: false, message: message, error: false, created: true}, { root: true }); 
//         } catch (error) {
//             const message = error.response.data?.message
//             commit('SET_LOADING', {loading: false, message: message, error: true}, { root: true }); 
//         }
//     },
// }

// const mutations = {
//     SET_GOALS: (state, newValue) => {
//         state.goals = newValue.data,
//         state.goals_page = newValue.current_page,
//         state.goals_last_page = newValue.last_page
//     },
//     SET_GOALS_PAGINATED: (state, newValue) => {
//         state.goals = [...state.goals, ...newValue.data]
//         state.goals_page = newValue.current_page
//         state.goals_last_page = newValue.last_page
//     },
//     SET_GOAL_DETIALS: (state, newValue) => {
//         state.goal_details = newValue
//     },
//     ADD_GOAL: (state, newValue) => {
//         state.goals = [newValue, ...state.goals]
//     },
//     SET_GOAL_TRANSACTIONS: (state, newValue) => {
//         state.goal_transactions = newValue.data
//         state.goal_transactions_page = newValue.current_page
//         state.goal_transactions_last_page = newValue.last_page
//     },
//     SET_GOAL_WITHDRAWALS: (state, newValue) => {
//         state.goal_withdrawals = newValue.data
//         state.goal_withdrawal_page = newValue.current_page
//         state.goal_withdrawal_last_page = newValue.last_page
//     },
//     ADD_GOAL_WITHDRAWAL: (state, newValue) => {
//         state.goal_withdrawals = [newValue, ...state.goal_withdrawals]
//     },
//     SET_INVESTMENTS: (state, newValue) => {
//         state.investments = newValue.data
//         state.investment_page = newValue.current_page
//         state.investment_last_page = newValue.last_page
//     },
//     SET_INVESTMENTS_PAGINATED: (state, newValue) => {
//         (state.investments = [...state.investments, newValue.data]), (state.investments_page = newValue.current_page);
//         state.investments_last_page = newValue.last_page;
//     },
//     SET_SAVINGS: (state, newValue) => {
//         state.savings = newValue.data
//         state.savings_page = newValue.current_page
//         state.savings_last_page = newValue.last_page
//     },
//     SET_SAVINGS_PAGINATED: (state, newValue) => {
//         (state.savings = [...state.savings, newValue.data]), (state.savings_page = newValue.current_page);
//         state.savings_last_page = newValue.last_page;
//     },
//     UPDATE_INVESTMENT: (state, newValue) => {
//         let new_array = state.investments.map(item=> {
//             if (item.id == newValue.id) {
//                 item = newValue
//             }
//             return item
//         })

//         state.investments = new_array
//     }
// }

// export default {
//     state,
//     getters,
//     actions,
//     mutations
// }