import { tokenHeaders, tokenMultiPartHeaders } from "../../services/helpers";
import Axios from "axios";

const ADMIN_URL = process.env.VUE_APP_ADMIN_URL;

const state = {
  investment_past_perfomance: [],
  investment_past_perfomance_page: 1,
  investment_past_perfomance_last_page: 1,
};

const getters = {
  investment_past_perfomance_info: (state) => ({
    past_perfomance: state.investment_past_perfomance,
    current_page: state.investment_past_perfomance_page,
    last_page: state.investment_past_perfomance_last_page,
  }),
};

const actions = {
  fetchInvestmentsPastPerfomances: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "investments/past/perfomance/list?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_INVESTMENT_PAST_PERFOMANCE", {
        data,
        last_page,
        current_page: params.page,
      });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  createInvestmentPastPerfomance: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: false, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "investments/past/perfomance/create";
      const response = await Axios.post(url, form, headers);
      const { message, data } = response.data;

      commit("ADD_INVESTMENT_PAST_PERFOMANCE", data);
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response?.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  getInvestmentExport: async ({ commit, rootState }) => {
    commit(
      "SET_LOADING",
      { loading: false, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "investments/export";
      const response = await Axios.get(url, headers);
      const { message } = response.data;

      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  updateInvestmentPastPerfomance: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: false, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "investments/past/perfomance/update";
      const response = await Axios.post(url, form, headers);
      const { message, data } = response.data;

      commit("UPDATE_INVESTMENT_PAST_PERFOMANCE", data);
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response?.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
};

const mutations = {
  SET_INVESTMENT_PAST_PERFOMANCE: (state, newValue) => {
    state.investment_past_perfomance = newValue.data;
    state.investment_past_perfomance_page = newValue.current_page;
    state.investment_past_perfomance_last_page = newValue.last_page;
  },
  ADD_INVESTMENT_PAST_PERFOMANCE: (state, newValue) => {
    state.investment_past_perfomance = [
      newValue,
      ...state.investment_past_perfomance,
    ];
  },
  UPDATE_INVESTMENT_PAST_PERFOMANCE: (state, newValue) => {
    let new_array = state.investment_past_perfomance.map((item) => {
      if (item.id == newValue.id) item = newValue;

      return item;
    });

    state.investment_past_perfomance = new_array;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
