import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import layout from "./modules/layout";
import data from "./modules/data";
import admin from "./modules/admin";
import loading from "./modules/loading";
import users from "./modules/users";
import finance from "./modules/finance";
import goals from "./modules/goals";
import group from "./modules/group";
import analysis from "./modules/analysis";
import loans from "./modules/loans";
import chat from "./modules/chat";
import referals from "./modules/referals";
import staticsite from "./modules/staticsite";
import statements from "./modules/statements";
import promotions from "./modules/promotions";
import investment from "./modules/investments";
import blacklist from "./modules/blacklist";
import payment_methods from "./modules/payment_methods";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    auth,
    loading,
    layout,
    data,
    admin,
    users,
    finance,
    goals,
    group,
    analysis,
    loans,
    chat,
    referals,
    staticsite,
    statements,
    promotions,
    investment,
    blacklist,
    payment_methods,
  },
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== "production",
});

export default store;
