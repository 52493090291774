import { tokenHeaders, tokenMultiPartHeaders } from "../../services/helpers";
import Axios from "axios";

const ADMIN_URL = process.env.VUE_APP_ADMIN_URL;

const state = {
  dashboard_statics: {},

  watchlist: [],
  watchlist_page: 1,
  watchlist_last_page: 1,

  watchlist_details: {},

  settings: {},
  commissions: {},

  srt_settings: {},
  srt_details: {},
};

const getters = {
  dashboard_statics: (state) => state.dashboard_statics,
  watchlist_info: (state) => ({
    watchlist: state.watchlist,
    current_page: state.watchlist_page,
    last_page: state.watchlist_last_page,
  }),
  watchlist_details: (state) => state.watchlist_details,
  settings: (state) => state.settings,
  commissions: (state) => state.commissions,
  srt_settings: (state) => state.srt_settings,
  srt_details: (state) => state.srt_details,
};

const actions = {
  fetchDashBoardStatics: async ({ commit, rootState }) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "statistics/dashboard";

      const response = await Axios.get(url, headers);
      const { data } = response.data;

      commit("SET_DASHBOARD_STATICS", data);
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchWatchList: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "watchlist/view?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_WATCHLIST", { data, last_page, current_page: params.page });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchWatchListDetails: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "watchlist/details?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_WATCHLIST_DETAILS", {
        data,
        last_page,
        current_page: params.page,
      });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  createWatchList: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenMultiPartHeaders({ ip_address, token });

      let url = ADMIN_URL + "watchlist/create";
      const response = await Axios.post(url, form, headers);
      const { message, data } = response.data;

      commit("ADD_WATCHLIST", data);
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  updateWatchList: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "watchlist/update/status";
      const response = await Axios.post(url, form, headers);
      const { message, data } = response.data;

      commit("UPDATE_WATCHLIST", data);
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchSettings: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "settings";
      const response = await Axios.get(url, headers);
      const { data } = response.data;
      commit("SET_SETTINGS", { data });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  updateSettings: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "settings/update";
      const response = await Axios.post(url, form, headers);
      const { message, data } = response.data;

      commit("UPDATE_SETTINGS", { data });
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  updateSettingsCommissions: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "commissions/update";
      const response = await Axios.post(url, form, headers);
      const { message, data } = response.data;

      commit("UPDATE_COMMISSIONS", { data });
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  fetchCommissions: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "commissions";
      const response = await Axios.get(url, headers);
      const { data } = response.data;
      commit("SET_COMMISSIONS", { data });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  fetchSrtSettings: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "srt_settings";
      const response = await Axios.get(url, headers);
      const { data } = response.data;
      commit("SET_SRT_SETTINGS", { data });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  updateSrtSettings: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "srt_settings/update";
      const response = await Axios.post(url, form, headers);
      const { message, data } = response.data;

      commit("UPDATE_SRT_SETTINGS", { data });
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchSrt0ffer: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "srt_offers/detail?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data } = response.data;

      commit("SET_SRT_OFFER_DETAILS", data);
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
};

const mutations = {
  SET_DASHBOARD_STATICS: (state, newValue) => {
    state.dashboard_statics = newValue;
  },
  SET_WATCHLIST: (state, newValue) => {
    state.watchlist = newValue?.data ?? [];
    state.watchlist_page = newValue?.current_page ?? 1;
    state.watchlist_last_page = newValue?.last_page ?? 1;
  },
  SET_WATCHLIST_DETAILS: (state, newValue) => {
    state.watchlist_details = newValue;
  },
  ADD_WATCHLIST: (state, newValue) => {
    state.watchlist = [newValue, ...state.watchlist];
  },
  UPDATE_WATCHLIST: (state, newValue) => {
    let newArray = state.watchlist.map((item) => {
      if (item.id == newValue.id) item = newValue;

      return item;
    });

    state.watchlist = newArray;
  },
  SET_SETTINGS: (state, newValue) => {
    state.settings = newValue?.data ?? {};
  },
  SET_COMMISSIONS: (state, newValue) => {
    state.commissions = newValue?.data ?? {};
  },
  SET_SRT_SETTINGS: (state, newValue) => {
    state.srt_settings = newValue?.data ?? {};
  },
  UPDATE_SETTINGS: (state, newValue) => {
    state.settings = newValue.data;
  },
  UPDATE_COMMISSIONS: (state, newValue) => {
    state.commissions = newValue?.data ?? {};
  },
  UPDATE_SRT_SETTINGS: (state, newValue) => {
    state.srt_settings = newValue?.data ?? {};
  },
  SET_SRT_OFFER_DETAILS: (state, newValue) => {
    console.log(newValue);
    state.srt_details = newValue;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
