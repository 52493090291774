import { tokenHeaders } from "../../services/helpers";
import Axios from "axios";

const ADMIN_URL = process.env.VUE_APP_ADMIN_URL;
const state = {
  loans: [],
  loans_page: 1,
  loans_last_page: 1,

  loan_details: {},
  loan_analytics: {},
  clients_analytics: {},

  accounts: [],
  accounts_page: 1,
  accounts_last_page: 1,

  account_transactions: [],
  account_transactions_page: 1,
  account_transactions_last_page: 1,

  transactions: [],
  transactions_page: 1,
  transactions_last_page: 1,

  transfers: [],
  transfer_page: 1,
  transfer_last_page: 1,

  account_details: {},

  deposits: [],
  deposits_page: 1,
  deposits_last_page: 1,

  deposits_analytics: {},

  withdrawals: [],
  withdrawals_page: 1,
  withdrawals_last_page: 1,

  utility_bills: [],
  utility_page: 1,
  utility_last_page: 1,

  reconciliations: [],
  reconciliations_page: 1,
  reconciliations_last_page: 1,

  single_reconciliation: {},
  single_reconciliation_page: 1,
  single_reconciliation_last_page: 1,

  // withdrawal_reconciliations:

  utility_item: {},
  utility_analytics: [],
  utility_bill_types: [],

  security_funds: [],
  security_page: 1,
  security_last_page: 1,

  interest_payments: [],
  interest_payments_page: 1,
  interest_payments_last_page: 1,

  interest: [],
  interest_page: 1,
  interest_last_page: 1,

  interest_allocations: [],
  interest_allocations_page: 1,
  interest_allocations_last_page: 1,

  interest_payment_schedules: [],
  interest_payment_schedules_page: 1,
  interest_payment_schedules_last_page: 1,

  interest_payment_units: [],
  interest_payment_units_page: 1,
  interest_payment_units_last_page: 1,

  guarantors: [],
  guarantors_page: 1,
  guarantors_last_page: 1,

  unallocated_deposits: [],
  allocated_deposits: [],
};

const getters = {
  loan_info: (state) => ({
    loans: state.loans,
    current_page: state.loans_page,
    last_page: state.loans_last_page,
  }),
  loan_details: (state) => state.loan_details,
  loan_analytics: (state) => state.loan_analytics,
  clients_analytics: (state) => state.clients_analytics,
  accounts_info: (state) => ({
    accounts: state.accounts,
    current_page: state.accounts_page,
    last_page: state.accounts_last_page,
  }),
  account_details: (state) => state.account_details,
  transactions_info: (state) => ({
    transactions: state.transactions,
    current_page: state.transactions_page,
    last_page: state.transactions_last_page,
  }),
  transfer_info: (state) => ({
    transfers: state.transfers,
    current_page: state.transfer_page,
    last_page: state.transfer_last_page,
  }),
  accounts_transactions_info: (state) => ({
    account_transactions: state.account_transactions,
    current_page: state.account_transactions_page,
    last_page: state.account_transactions_last_page,
  }),
  deposits_info: (state) => ({
    deposits: state.deposits,
    current_page: state.deposits_page,
    last_page: state.deposits_last_page,
  }),
  deposits_analytics: (state) => state.deposits_analytics,
  withdrawal_info: (state) => ({
    withdrawals: state.withdrawals,
    current_page: state.withdrawals_page,
    last_page: state.withdrawals_last_page,
  }),
  utility_info: (state) => ({
    bills: state.utility_bills,
    current_page: state.utility_page,
    last_page: state.last_page,
  }),
  utility_item: (state) => state.utility_item,
  utility_analytics: (state) => state.utility_analytics,
  utility_bill_types: (state) => state.utility_bill_types,
  reconciliations_info: (state) => ({
    reconciliations: state.reconciliations,
    current_page: state.reconciliations_page,
    last_page: state.reconciliations_last_page,
  }),
  single_reconciliations_info: (state) => ({
    deposits: state.single_reconciliation.deposits,
    current_page: state.single_reconciliation_page,
    last_page: state.single_reconciliation_last_page,
  }),
  security_funds_info: (state) => ({
    security_funds: state.security_funds,
    current_page: state.security_page,
    last_page: state.security_last_page,
  }),
  guarantors_info: (state) => ({
    guarantors: state.guarantors,
    current_page: state.guarantors_page,
    last_page: state.guarantors_last_page,
  }),
  interests_info: (state) => ({
    interest: state.interest,
    current_page: state.interest_page,
    last_page: state.interest_last_page,
  }),
  interests_allocations_info: (state) => ({
    allocations: state.interest_allocations,
    current_page: state.interest_allocations_page,
    last_page: state.interest_allocations_last_page,
  }),
  interests_payment_units_info: (state) => ({
    payment_units: state.interest_payment_units,
    current_page: state.interest_payment_units_page,
    last_page: state.interest_payment_units_last_page,
  }),
  interests_payments_info: (state) => ({
    interest_payments: state.interest_payments,
    current_page: state.interest_payments_page,
    last_page: state.interest_payments_last_page,
  }),
  interests_payments_schedules_info: (state) => ({
    interest_payment_schedules: state.interest_payment_schedules,
    current_page: state.interest_payment_schedules_page,
    last_page: state.interest_payment_schedules_last_page,
  }),
  unallocated_deposits_info: (state) => ({
    unallocated_deposits: state.unallocated_deposits,
    allocated_deposits: state.allocated_deposits,
  }),
};

const actions = {
  fetchLoans: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "loans/view?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_LOANS", { data, last_page, current_page: params.page });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchLoanDetails: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "loans/detail?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data } = response.data;

      commit("SET_LOAN_DETAILS", data);
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchLoanAnalytics: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "loans/analytics?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data } = response.data;

      commit("SET_LOAN_ANALYTICS", data);
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchClientLoanAnalytics: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "loans/client/analytics?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data } = response.data;

      commit("SET_LOAN_ANALYTICS", data);
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchLoanTransactions: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "loans/transactions?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_TRANSACTIONS", {
        data,
        current_page: params.page,
        last_page,
      });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchLoanGuarantors: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "loans/guarantors?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_GUARANTORS", { data, current_page: params.page, last_page });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  payLoan: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });
      let url = ADMIN_URL + "loans/pay";

      const response = await Axios.post(url, form, headers);
      const { message, data } = response.data;

      commit("SET_LOAN_DETAILS", data);
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  exportLoans: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "loans/export?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { message } = response.data;

      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchAccounts: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "accounts/view?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_ACCOUNTS", { data, last_page, current_page: params.page });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchAccountDetails: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "accounts/details?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data } = response.data;

      commit("SET_ACCOUNT_DETAILS", data);
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchAccountTransactions: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "accounts/transactions?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_ACCOUNT_TRANSACTIONS", {
        data,
        last_page,
        current_page: params.page,
      });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchClientsAnalytics: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "clients/analytics?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data } = response.data;

      commit("SET_CLIENTS_ANALYTICS", data);
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchAccountExport: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "accounts/export?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }

      const response = await Axios.get(url, headers);
      const { message } = response.data;

      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchAccountClientExport: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "accounts/client/export?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }

      const response = await Axios.get(url, headers);
      const { message } = response.data;

      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  createAccount: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "accounts/create";
      const response = await Axios.post(url, form, headers);
      const { message, data } = response.data;

      commit("ADD_ACCOUNT", data);
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  updateAccount: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "accounts/update";
      const response = await Axios.post(url, form, headers);
      const { message, data } = response.data;

      commit("UPDATE_ACCOUNT", data);
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchDeposits: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "deposits/view?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_DEPOSITS", { data, last_page, current_page: params.page });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchDepositsAnalytics: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "deposits/analytics?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data } = response.data;

      commit("SET_DEPOSIT_ANALYTICS", data);
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchDepositsExport: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "deposits/export?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }

      const response = await Axios.get(url, headers);
      const { message } = response.data;

      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  updateDepositState: async ({ commit, rootState }, item) => {
    commit("SET_DEPOSITS", {
      data: item.data,
      last_page: item.last_page,
      current_page: item.current_page,
    });
  },
  fetchWithdrawals: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "withdrawals/view?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_WITHDRAWALS", { data, last_page, current_page: params.page });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchWithDrawalExport: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "withdrawals/export?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }

      const response = await Axios.get(url, headers);
      const { message } = response.data;

      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchTransfers: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "transfer/view?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }

      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_TRANSFERS", { data, last_page, current_page: params.page });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchTransferTransactions: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "transfer/transactions?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }

      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_ACCOUNT_TRANSACTIONS", {
        data,
        last_page,
        current_page: params.page,
      });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchTransferExport: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "wallet/transfer/export?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }

      const response = await Axios.get(url, headers);
      const { message } = response.data;

      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  createMpesaWithdrawal: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "withdrawal/mpesa";
      const response = await Axios.post(url, form, headers);
      const { message } = response.data;

      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchUtilityBills: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "utility/view?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }

      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_UTILITY_BILLS", {
        data,
        last_page,
        current_page: params.page,
      });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchBillAnalytics: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "utility/analytics?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }

      const response = await Axios.get(url, headers);
      const { data } = response.data;
      const analytics = JSON.parse(data);

      commit("SET_BILL_ANALYTICS", analytics);
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchUtilityBillInfo: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "utility/retrieve?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }

      const response = await Axios.get(url, headers);
      const { data } = response.data;

      commit("SET_BILL_INFO", data);
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchUtilityBillTypes: async ({ commit, rootState }) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "utility/types";

      const response = await Axios.get(url, headers);
      const { data } = response.data;

      commit("SET_BILL_TYPES", data);
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  createUtilityBill: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "utility/create";
      const response = await Axios.post(url, form, headers);
      const { data, message } = response.data;

      commit("ADD_UTILITY_BILL", data);
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  // Reconciliations
  createReconciliations: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });
      let url = ADMIN_URL + "deposits/reconciliate/create";

      const response = await Axios.post(url, form, headers);
      const { data } = response.data;

      commit("ADD_RECONCILIATION", data);
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  uploadReconciliations: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "deposits/reconciliate/upload";
      const response = await Axios.post(url, form, headers);
      const { data, message } = response.data;
      const { final_deposits, unallocated_deposits } = data;

      commit("SET_ALLOCATED_DEPOSITS", final_deposits);
      commit("SET_UNALLOCATED_DEPOSITS", unallocated_deposits);
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  bulkReconciliations: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "deposits/reconciliate/bulk";
      const response = await Axios.post(url, form, headers);
      const { data, message } = response.data;

      commit("ADD_RECONCILIATION", data);
      commit("SET_ALLOCATED_DEPOSITS", []);

      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  bulkUnAllocatedDeposit: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "deposits/reconciliate/bulk/unallocated";
      const response = await Axios.post(url, form, headers);
      const { data, message } = response.data;

      commit("ADD_RECONCILIATION", data);
      commit("SET_UNALLOCATED_DEPOSITS", []);

      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchReconciliations: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });
      let url = ADMIN_URL + "deposits/reconciliate/view?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_RECONCILIATIONS", {
        data,
        last_page,
        current_page: params.page,
      });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchSingleReconciliation: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });
      let url = ADMIN_URL + "deposits/reconciliate/retrieve?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_SINGLE_RECONCILIATIONS", {
        data,
        last_page,
        current_page: params.page,
      });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchSecurityFunds: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });
      let url = ADMIN_URL + "loans/client/security?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_SECURITY_FUNDS", {
        data,
        last_page,
        current_page: params.page,
      });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  // Interest paymentss
  createInterest: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "interest/create";
      const response = await Axios.post(url, form, headers);
      const { message, data } = response.data;

      // commit('ADD_INTEREST', data)
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  // Interest paymentss
  createPaymentSchedule: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "interest/payment/create";
      const response = await Axios.post(url, form, headers);
      const { message, data } = response.data;

      // commit('ADD_INTEREST', data)
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchInterestPayments: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "interest/view/interests?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_INTERESTS_PAYMENTS", {
        data,
        last_page,
        current_page: params.page,
      });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchInterestsPaymentsPaginated: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "interest/view/interests?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_INTERESTS_PAYMENTS_PAGINATED", {
        data,
        last_page,
        current_page: params.page,
      });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchInterest: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "interest/view?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_INTERESTS", { data, last_page, current_page: params.page });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  exportInterest: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: false, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "interest/export?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { message } = response.data;

      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  exportAllocationsSchedule: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: false, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "interest/allocation_schedule/export?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { message } = response.data;

      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  exportPaymentsSchedule: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: false, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "interest/payment_schedule/export?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { message } = response.data;

      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchInterestAllocations: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "interest/view/allocations?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;
      commit("SET_INTERESTS_ALLOCATIONS", {
        data,
        last_page,
        current_page: params.page,
      });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  updateAllocationStatus: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: false, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "interest/payment/allocation/status";
      const response = await Axios.post(url, form, headers);
      const { message, data } = response.data;
      commit("UPDATE_ALLOCATIONS_STATUS", data);
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response?.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchInterestPaymentUnits: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "interest/view/payments/units?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;
      commit("SET_INTERESTS_PAYMENT_UNITS", {
        data,
        last_page,
        current_page: params.page,
      });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchPaymentSchedule: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "interest/view/payment_schedule?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;
      commit("SET_INTERESTS_PAYMENT_SCHEDULES", {
        data,
        last_page,
        current_page: params.page,
      });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  updatePaymentScheduleStatus: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: false, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "interest/payment/schedule/status";
      const response = await Axios.post(url, form, headers);
      const { message, data } = response.data;
      commit("UPDATE_PAYMENT_SCHEDULE_STATUS", data);
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response?.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  exportUserTransactions: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "transactions/export?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { message } = response.data;

      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  generateClientTransactionsPdf: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "transactions/clients/pdf?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { message } = response.data;

      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      console.log(error);
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
};

const mutations = {
  SET_LOANS: (state, newValue) => {
    state.loans = newValue.data;
    state.loans_page = newValue.current_page;
    state.loans_last_page = newValue.last_page;
  },
  SET_LOAN_DETAILS: (state, newValue) => {
    state.loan_details = newValue;
  },
  SET_LOAN_ANALYTICS: (state, newValue) => {
    state.loan_analytics = newValue;
  },
  UPDATE_LOANS: (state, newValue) => {
    let newArray = state.loans.map((item) => {
      if (item.id == newValue.id) {
        item = newValue;
      }

      return item;
    });
    state.loans = newArray;
  },
  SET_CLIENTS_ANALYTICS: (state, newValue) => {
    state.clients_analytics = newValue || {};
  },
  SET_ACCOUNTS: (state, newValue) => {
    state.accounts = newValue.data;
    state.accounts_page = newValue.current_page;
    state.accounts_last_page = newValue.last_page;
  },
  SET_ACCOUNT_TRANSACTIONS: (state, newValue) => {
    state.account_transactions = newValue.data;
    state.account_transactions_page = newValue.current_page;
    state.account_transactions_last_page = newValue.last_page;
  },
  SET_ACCOUNT_DETAILS: (state, newValue) => {
    state.account_details = newValue;
  },
  ADD_ACCOUNT: (state, newValue) => {
    state.accounts = [newValue, ...state.accounts];
  },
  UPDATE_ACCOUNT: (state, newValue) => {
    let newArray = state.accounts.map((item) => {
      if (item.id == newValue.id) {
        item = newValue;
      }
      return item;
    });
    state.accounts = newArray;
  },
  SET_TRANSFERS: (state, newValue) => {
    state.transfers = newValue.data;
    state.transfer_page = newValue.current_page;
    state.transfer_last_page = newValue.last_page;
  },
  SET_TRANSACTIONS: (state, newValue) => {
    state.transactions = newValue.data;
    state.transactions_page = newValue.current_page;
    state.transactions_last_page = newValue.last_page;
  },
  SET_DEPOSITS: (state, newValue) => {
    state.deposits = newValue.data;
    state.deposits_page = newValue.current_page;
    state.deposits_last_page = newValue.last_page;
  },
  SET_DEPOSIT_ANALYTICS: (state, newValue) => {
    state.deposits_analytics = newValue;
  },
  SET_WITHDRAWALS: (state, newValue) => {
    state.withdrawals = newValue.data;
    state.withdrawals_page = newValue.current_page;
    state.withdrawals_last_page = newValue.last_page;
  },
  SET_UTILITY_BILLS: (state, newValue) => {
    state.utility_bills = newValue.data;
    state.utility_page = newValue.current_page;
    state.utility_last_page = newValue.last_page;
  },
  SET_BILL_ANALYTICS: (state, newValue) => {
    state.utility_analytics = newValue;
  },
  ADD_UTILITY_BILL: (state, newValue) => {
    state.utility_bills = [newValue, ...state.utility_bills];
  },
  SET_BILL_INFO: (state, newValue) => {
    state.utility_item = newValue;
  },
  SET_BILL_TYPES: (state, newValue) => {
    state.utility_bill_types = newValue;
  },
  ADD_RECONCILIATION: (state, newValue) => {
    state.reconciliations = [newValue, ...state.reconciliations];
  },
  SET_RECONCILIATIONS: (state, newValue) => {
    state.reconciliations = newValue.data || [];
    state.reconciliations_page = newValue.current_page;
    state.reconciliations_last_page = newValue.last_page;
  },
  SET_SINGLE_RECONCILIATIONS: (state, newValue) => {
    state.single_reconciliation = newValue.data || {};
    state.single_reconciliation_page = newValue.current_page;
    state.single_reconciliation_last_page = newValue.last_page;
  },
  SET_SECURITY_FUNDS: (state, newValue) => {
    state.security_funds = newValue.data ?? [];
    state.security_page = newValue.current_page;
    state.security_last_page = newValue.last_page;
  },
  // PYAMENTS
  SET_INTERESTS_PAYMENTS: (state, newValue) => {
    state.interest_payments = newValue.data;
    state.interest_payments_page = newValue.current_page;
    state.interest_payments_last_page = newValue.last_page;
  },
  SET_INTERESTS_PAYMENTS_PAGINATED: (state, newValue) => {
    (state.interest_payments = [...state.interest_payments, newValue.data]),
      (state.interest_payments_page = newValue.current_page);
    state.interest_payments_last_page = newValue.last_page;
  },
  // interest
  SET_INTERESTS: (state, newValue) => {
    state.interest = newValue.data;
    state.interest_page = newValue.current_page;
    state.interest_last_page = newValue.last_page;
  },
  ADD_INTEREST: (state, newValue) => {
    state.interest = [newValue, ...state.interest];
  },
  // INTEREST Allocations
  SET_INTERESTS_ALLOCATIONS: (state, newValue) => {
    state.interest_allocations = newValue.data;
    state.interest_allocations_page = newValue.current_page;
    state.interest_allocations_last_page = newValue.last_page;
  },
  UPDATE_ALLOCATIONS_STATUS: (state, newValue) => {
    let newArray = state.interest_allocations.map((item) => {
      if (item.id == newValue.id) {
        item = newValue;
      }
      return item;
    });
    state.interest_allocations = newArray;
  },
  // iNTEREST PAYMENT SCHDULES
  SET_INTERESTS_PAYMENT_UNITS: (state, newValue) => {
    state.interest_payment_units = newValue.data;
    state.interest_payment_units_page = newValue.current_page;
    state.interest_payment_units_last_page = newValue.last_page;
  },
  // INTEREST PAYMENT SCHEDULES
  SET_INTERESTS_PAYMENT_SCHEDULES: (state, newValue) => {
    state.interest_payment_schedules = newValue.data;
    state.interest_payment_schedules_page = newValue.current_page;
    state.interest_payment_schedules_last_page = newValue.last_page;
  },
  UPDATE_PAYMENT_SCHEDULE_STATUS: (state, newValue) => {
    let newArray = state.interest_payment_schedules.map((item) => {
      if (item.id == newValue.id) {
        item = newValue;
      }
      return item;
    });
    state.interest_payment_schedules = newArray;
  },
  SET_GUARANTORS: (state, newValue) => {
    state.guarantors = newValue.data ?? [];
    state.guarantors_page = newValue.current_page;
    state.guarantors_last_page = newValue.last_page;
  },
  SET_ALLOCATED_DEPOSITS: (state, newValue) => {
    state.allocated_deposits = newValue;
  },
  SET_UNALLOCATED_DEPOSITS: (state, newValue) => {
    state.unallocated_deposits = newValue;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
