<template>
  <a :href="to || '#'" target="_blank">
    <div v-if="src">
      <img :src="src" :alt="alt" class="rounded-circle avatar-xs">
    </div>
    <div v-else class="avatar-xs">
      <span v-if="alt" class="avatar-title rounded-circle">{{ alt.substring(0, 1) }}</span>
    </div>
  </a>
</template>

<script>
export default {
    props: [ 'src', 'alt', 'to' ]
}
</script>

<style>

</style>