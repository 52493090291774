<template>
  <b-modal   
    @ok.prevent="$emit('ok')"
    :id="id"
    :title="title"
    :ok-title="!isLoading ? customOkTitle : okLoadingMessage"
    :busy="isLoading"
    :no-close-on-esc="isLoading"
    :no-close-on-backdrop="isLoading"
    :hide-header-close="isLoading"
    :header-bg-variant="''"
    :ok-variant="variant"
    scrollable
    :size="size ? size : 'md'"
    centered
    title-class="font-18">
        <slot></slot>
    </b-modal>
</template>

<script>
export default {
    data() {
        return {
            
        }        
    },
    computed: {
        customOkTitle() {
            if(this.okTitle){ 
                return this.okTitle
            } else if(this.action === 'add') {
                return 'Save'
            } else if(this.action === 'edit') {
                return 'Edit'
            } else if(this.action === 'delete') {
                return 'Delete'
            } else {
                return 'OK'
            }
        },
        okLoadingMessage() {
            if(this.action === 'add') {
                return 'Saving...'
            } else if(this.action === 'edit') {
                return 'Updating...'
            } else if(this.action === 'delete') {
                return 'Deleting...'
            } else {
                return 'Please wait...'
            }
        },
        variant() {
            if(this.action === 'add') {
                return 'primary'
            } else if(this.action === 'edit') {
                return 'primary'
            } else if(this.action === 'delete') {
                return 'danger'
            } else {
                return 'primary'
            }
        },
    },
    props: [ 'id', 'title', 'isLoading', 'action', 'size', 'okTitle' ]

}
</script>

<style>

</style>