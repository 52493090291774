import Axios from "axios";
import { tokenHeaders, customParse, tokenBotHeaders } from "../../services/helpers";

const ADMIN_URL = process.env.VUE_APP_ADMIN_URL;
const BOT_URL = process.env.VUE_APP_CHAT_URL + "accounts/auth/";

const state = {
  deposit_statements: [],
  deposit_statements_page: 1,
  deposit_statements_last_page: 1,

  deposit_details: [],
  deposit_details_page: 1,
  deposit_details_last_page: 1,

  goal_withdrawal_details: [],
  goal_withdrawal_details_page: 1,
  goal_withdrawal_details_last_page: 1,

  revenue_statements: [],
  revenue_statements_page: 1,
  revenue_statements_last_page: 1,

  revenue_details: [],
  revenue_details_page: 1,
  revenue_details_last_page: 1,

  utility_statements: [],
  utility_statements_page: 1,
  utility_statements_last_page: 1,

  utility_details: [],
  utility_details_page: 1,
  utility_details_last_page: 1,

  wallet_statements: [],
  wallet_statements_page: 1,
  wallet_statements_last_page: 1,

  wallet_details: [],
  wallet_details_page: 1,
  wallet_details_last_page: 1,
};

const getters = {
  deposit_statements_info: state => ({
    deposit_statements: state.deposit_statements,
    current_page: state.deposit_statements_page,
    last_page: state.deposit_statements_last_page,
  }),
  deposit_statements_details_info: state => ({
    deposit_details: state.deposit_details,
    current_page: state.deposit_details_page,
    last_page: state.deposit_details_last_page,
  }),
  goal_withdrawal_statements_details_info: state => ({
    goal_withdrawal_details: state.goal_withdrawal_details,
    current_page: state.goal_withdrawal_details_page,
    last_page: state.goal_withdrawal_details_last_page,
  }),
  revenue_statements_info: state => ({
    revenue_statements: state.revenue_statements,
    current_page: state.revenue_statements_page,
    last_page: state.revenue_statements_last_page,
  }),
  revenue_statements_details_info: state => ({
    revenue_details: state.revenue_details,
    current_page: state.revenue_details_page,
    last_page: state.revenue_details_last_page,
  }),
  utility_statements_info: state => ({
    utility_statements: state.utility_statements,
    current_page: state.utility_statements_page,
    last_page: state.utility_statements_last_page,
  }),
  utility_statements_details_info: state => ({
    utility_details: state.utility_details,
    current_page: state.utility_details_page,
    last_page: state.utility_details_last_page,
  }),
  wallet_statements_info: state => ({
    wallet_statements: state.wallet_statements,
    current_page: state.wallet_statements_page,
    last_page: state.wallet_statements_last_page,
  }),
  wallet_statements_details_info: state => ({
    wallet_details: state.wallet_details,
    current_page: state.wallet_details_page,
    last_page: state.wallet_details_last_page,
  }),
};

const actions = {
  fetchDepositStatement: async ({ commit, rootState }, params) => {
    commit("SET_LOADING", { loading: true, message: "", error: false }, { root: true });
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "statements/deposits?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_DEPOSIT_STATEMENT", { data, last_page, current_page: params.page });
      commit("SET_LOADING", { loading: false, message: "", error: false, created: true }, { root: true });
    } catch (error) {
      const message = error.response.data?.message;
      commit("SET_LOADING", { loading: false, message: message, error: true }, { root: true });
    }
  },
  fetchDepositDetailsStatement: async ({ commit, rootState }, params) => {
    commit("SET_LOADING", { loading: true, message: "", error: false }, { root: true });
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });
      let url = ADMIN_URL + "statements/deposits/details?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_DEPOSIT_STATEMENT_DETAILS", { data, last_page, current_page: params.page });
      commit("SET_LOADING", { loading: false, message: "", error: false, created: true }, { root: true });
    } catch (error) {
      const message = error.response.data?.message;
      commit("SET_LOADING", { loading: false, message: message, error: true }, { root: true });
    }
  },
  fetchGoalWithdrawalStatement: async ({ commit, rootState }, params) => {
    commit("SET_LOADING", { loading: true, message: "", error: false }, { root: true });
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "statements/goal_withdrawals?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;
      commit("SET_DEPOSIT_STATEMENT", { data, last_page, current_page: params.page });
      commit("SET_LOADING", { loading: false, message: "", error: false, created: true }, { root: true });
    } catch (error) {
      const message = error.response.data?.message;
      commit("SET_LOADING", { loading: false, message: message, error: true }, { root: true });
    }
  },
  fetchGoalWithdrawalDetailStatement: async ({ commit, rootState }, params) => {
    commit("SET_LOADING", { loading: true, message: "", error: false }, { root: true });
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });
      let url = ADMIN_URL + "statements/goal_withdrawals/details?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_GOAL_WITHDRAWAL_STATEMENT_DETAILS", { data, last_page, current_page: params.page });
      commit("SET_LOADING", { loading: false, message: "", error: false, created: true }, { root: true });
    } catch (error) {
      const message = error.response.data?.message;
      commit("SET_LOADING", { loading: false, message: message, error: true }, { root: true });
    }
  },
  fetchRevenues: async ({ commit, rootState }, params) => {
    commit("SET_LOADING", { loading: true, message: "", error: false }, { root: true });
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "statements/revenues?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_REVENUE_STATEMENT", { data, last_page, current_page: params.page });
      commit("SET_LOADING", { loading: false, message: "", error: false, created: true }, { root: true });
    } catch (error) {
      const message = error.response.data?.message;
      commit("SET_LOADING", { loading: false, message: message, error: true }, { root: true });
    }
  },
  fetchRevenuesDetailStatement: async ({ commit, rootState }, params) => {
    commit("SET_LOADING", { loading: true, message: "", error: false }, { root: true });
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });
      let url = ADMIN_URL + "statements/revenues/details?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;
      commit("SET_REVENUE_STATEMENT_DETAILS", { data, last_page, current_page: params.page });
      commit("SET_LOADING", { loading: false, message: "", error: false, created: true }, { root: true });
    } catch (error) {
      const message = error.response.data?.message;
      commit("SET_LOADING", { loading: false, message: message, error: true }, { root: true });
    }
  },
  fetchUtilities: async ({ commit, rootState }, params) => {
    commit("SET_LOADING", { loading: true, message: "", error: false }, { root: true });
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "statements/utilities?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;
      console.log(data)
      commit("SET_UTILITY_STATEMENT", { data, last_page, current_page: params.page });
      commit("SET_LOADING", { loading: false, message: "", error: false, created: true }, { root: true });
    } catch (error) {
      const message = error.response.data?.message;
      commit("SET_LOADING", { loading: false, message: message, error: true }, { root: true });
    }
  },
  fetchUtilitiesDetailStatement: async ({ commit, rootState }, params) => {
    commit("SET_LOADING", { loading: true, message: "", error: false }, { root: true });
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });
      let url = ADMIN_URL + "statements/utilities/details?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;
      commit("SET_UTILITIES_STATEMENT_DETAILS", { data, last_page, current_page: params.page });
      commit("SET_LOADING", { loading: false, message: "", error: false, created: true }, { root: true });
    } catch (error) {
      const message = error.response.data?.message;
      commit("SET_LOADING", { loading: false, message: message, error: true }, { root: true });
    }
  },
  fetchWalletWithdrawal: async ({ commit, rootState }, params) => {
    commit("SET_LOADING", { loading: true, message: "", error: false }, { root: true });
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "statements/wallet_withdrawals?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;
      console.log(data)
      commit("SET_WALLET_STATEMENT", { data, last_page, current_page: params.page });
      commit("SET_LOADING", { loading: false, message: "", error: false, created: true }, { root: true });
    } catch (error) {
      const message = error.response.data?.message;
      commit("SET_LOADING", { loading: false, message: message, error: true }, { root: true });
    }
  },
  fetchWalletDetails: async ({ commit, rootState }, params) => {
    commit("SET_LOADING", { loading: true, message: "", error: false }, { root: true });
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });
      let url = ADMIN_URL + "statements/wallet_withdrawals/details?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;
      commit("SET_WALLET_STATEMENT_DETAILS", { data, last_page, current_page: params.page });
      commit("SET_LOADING", { loading: false, message: "", error: false, created: true }, { root: true });
    } catch (error) {
      const message = error.response.data?.message;
      commit("SET_LOADING", { loading: false, message: message, error: true }, { root: true });
    }
  },
  exportDepositPdf: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: false, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "statements/deposits/pdf/export?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { message } = response.data;

      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  exportGoalWithdrawalPdf: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: false, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "statements/goal_withdrawals/pdf/export?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { message } = response.data;

      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  exportRevenuesPdf: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: false, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "statements/revenues/pdf/export?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { message } = response.data;

      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  exportUtilitiesPdf: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: false, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "statements/utilities/pdf/export?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { message } = response.data;

      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  exportWalletWithdrawalsPdf: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: false, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "statements/wallet_withdrawals/pdf/export?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { message } = response.data;

      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
};

const mutations = {
  SET_DEPOSIT_STATEMENT: (state, newValue) => {
    state.deposit_statements = newValue.data;
    state.deposit_statements_page = newValue.current_page;
    state.deposit_statements_last_page = newValue.last_page;
  },
  SET_DEPOSIT_STATEMENT_DETAILS: (state, newValue) => {
    state.deposit_details = newValue.data;
    state.deposit_details_page = newValue.current_page;
    state.deposit_details_last_page = newValue.last_page;
  },
  SET_GOAL_WITHDRAWAL_STATEMENT_DETAILS: (state, newValue) => {
    state.goal_withdrawal_details = newValue.data;
    state.goal_withdrawal_details_page = newValue.current_page;
    state.goal_withdrawal_details_last_page = newValue.last_page;
  },
  SET_REVENUE_STATEMENT: (state, newValue) => {
    state.revenue_statements = newValue.data;
    state.revenue_statements_page = newValue.current_page;
    state.revenue_statements_last_page = newValue.last_page;
  },
  SET_REVENUE_STATEMENT_DETAILS: (state, newValue) => {
    state.revenue_details = newValue.data;
    state.revenue_details_page = newValue.current_page;
    state.revenue_details_last_page = newValue.last_page;
  },
  SET_UTILITY_STATEMENT: (state, newValue) => {
    state.utility_statements = newValue.data;
    state.utility_statements_page = newValue.current_page;
    state.utility_statements_last_page = newValue.last_page;
  },
  SET_UTILITIES_STATEMENT_DETAILS: (state, newValue) => {
    state.utility_details = newValue.data;
    state.utility_details_page = newValue.current_page;
    state.utility_details_last_page = newValue.last_page;
  },
  SET_WALLET_STATEMENT: (state, newValue) => {
    state.wallet_statements = newValue.data;
    state.wallet_statements_page = newValue.current_page;
    state.wallet_statements_last_page = newValue.last_page;
  },
  SET_WALLET_STATEMENT_DETAILS: (state, newValue) => {
    state.wallet_details = newValue.data;
    state.wallet_details_page = newValue.current_page;
    state.wallet_details_last_page = newValue.last_page;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
