import { tokenHeaders, tokenMultiPartHeaders } from "../../services/helpers";
import Axios from "axios";

const ADMIN_URL = process.env.VUE_APP_ADMIN_URL;
const CLIENT_URL = process.env.VUE_APP_CLIENT_URL;
const state = {
  goals: [],
  goals_page: 1,
  goals_last_page: 1,

  goal_details: {},

  goal_transactions: [],
  goal_transactions_page: 1,
  goal_transactions_last_page: 1,

  investments: [],
  investment_page: 1,
  investment_last_page: 1,

  view_investment: {},

  savings: [],
  savings_page: 1,
  savings_last_page: 1,

  goal_withdrawals: [],
  goal_withdrawal_page: 1,
  goal_withdrawal_last_page: 1,

  investment_survey: [],
  investment_survey_page: 1,
  investment_survey_last_page: 1,

  investment_class: [],
  investment_class_page: 1,
  investment_class_last_page: 1,

  srt_offers: [],
  srt_offers_page: 1,
  srt_offers_last_page: 1,

  srt_offer: {},
};

const getters = {
  goals_info: (state) => ({
    goals: state.goals,
    current_page: state.goals_page,
    last_page: state.goals_last_page,
  }),
  goal_details: (state) => state.goal_details,
  goal_transactions: (state) => ({
    transactions: state.goal_transactions,
    current_page: state.goal_transactions_page,
    last_page: state.goal_transactions_last_page,
  }),
  goal_withdrawals: (state) => ({
    withdrawals: state.goal_withdrawals,
    current_page: state.goal_withdrawal_page,
    last_page: state.goal_withdrawal_last_page,
  }),
  investment_info: (state) => ({
    investments: state.investments,
    current_page: state.investment_page,
    last_page: state.investment_last_page,
  }),
  view_investment_info: (state) => ({ investment: state.view_investment }),
  investment_class_info: (state) => ({
    investment_class: state.investment_class,
    current_page: state.investment_class_page,
    last_page: state.investment_class_last_page,
  }),
  investment_survey_info: (state) => ({
    investment_survey: state.investment_survey,
    current_page: state.investment_survey_page,
    last_page: state.investment_survey_last_page,
  }),
  srt_offers_info: (state) => ({
    srt_offers: state.srt_offers,
    current_page: state.srt_offers_page,
    last_page: state.srt_offers_last_page,
  }),
  srt_offer_info: (state) => ({
    srt_offer: state.srt_offer,
  }),
  saving_info: (state) => ({
    savings: state.savings,
    current_page: state.savings_page,
    last_page: state.savings_last_page,
  }),
};

const actions = {
  fetchGoals: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "goals/view?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_GOALS", { data, last_page, current_page: params.page });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchActiveGoals: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "goals/view/active?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_GOALS", { data, last_page, current_page: params.page });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchGoalsPaginated: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "goals/view?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_GOALS_PAGINATED", {
        data,
        last_page,
        current_page: params.page,
      });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchGoalDetails: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "goals/retrieve?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data } = response.data;

      commit("SET_GOAL_DETIALS", data);
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchGoalTransacions: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "goals/transaction?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_GOAL_TRANSACTIONS", {
        data,
        last_page,
        current_page: params.page,
      });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  updateGoalStatus: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );

    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "goals/update/status";
      const response = await Axios.post(url, form, headers);
      const { message, data } = response.data;

      commit("UPDATE_GOALS", data);
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  deleteGoal: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );

    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "goals/delete";
      const response = await Axios.post(url, form, headers);
      const { message, data } = response.data;

      commit("UPDATE_GOALS", data);
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  fetchInvestments: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "investments/view?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;
      commit("SET_INVESTMENTS", { data, last_page, current_page: params.page });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  viewInvestment: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "investments/retrieve?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data } = response.data;
      commit("SET_VIEW_INVESTMENT", data);
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  fetchInvestmentsPaginated: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "investments/view?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_INVESTMENTS_PAGINATED", {
        data,
        last_page,
        current_page: params.page,
      });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  fetchInvestmentsClass: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "investments/class/view?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_INVESTMENTS_CLASS", {
        data,
        last_page,
        current_page: params.page,
      });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchInvestmentsClassPaginated: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "investments/class/view?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_INVESTMENTS_CLASS_PAGINATED", {
        data,
        last_page,
        current_page: params.page,
      });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  createInvestment: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );

    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenMultiPartHeaders({ ip_address, token });

      let url = ADMIN_URL + "investments/create";
      const response = await Axios.post(url, form, headers);
      const { message, data } = response.data;

      commit("ADD_INVESTMENT_SURVEY", data);
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response?.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  updateInvestment: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );

    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenMultiPartHeaders({ ip_address, token });

      let url = ADMIN_URL + "investments/update";
      const response = await Axios.post(url, form, headers);
      const { message, data } = response.data;

      commit("UPDATE_INVESTMENT", data);
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response?.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  fetchSurvey: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "investments/survey/view?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;
      commit("SET_INVESTMENTS_SURVEY", {
        data,
        last_page,
        current_page: params.page,
      });

      commit("SET_INVESTMENTS_SURVEY", {
        data,
        last_page,
        current_page: params.page,
      });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  fetchSurveyPaginated: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "investments/survey/view?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;
      commit("SET_INVESTMENTS_SURVEY_PAGINATED", {
        data,
        last_page,
        current_page: params.page,
      });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },

  createInvestmentSurvey: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "investments/survey/create";
      const response = await Axios.post(url, form, headers);
      const { message, data } = response.data;

      commit("ADD_INVESTMENT_SURVEY", data);
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response?.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  updateInvestmentSurvey: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );

    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "investments/survey/update";
      const response = await Axios.post(url, form, headers);
      const { message, data } = response.data;

      commit("UPDATE_INVESTMENT_SURVEY", data);
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response?.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  deleteInvestmentSurvey: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "investments/survey/delete?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }

      const response = await Axios.delete(url, headers);
      const { message } = response.data;

      commit("DELETE_INVESTMENT_SURVEY", params.id);
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchOffers: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "srt_offers/view?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;
      commit("SET_SRT_OFFERS", { data, last_page, current_page: params.page });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchSrtOffer: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "srt_offers/detail?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data } = response.data;
      commit("SET_SRT_OFFER", { data });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  createSrtOffers: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "srt_offers/create";
      const response = await Axios.post(url, form, headers);
      const { message, data } = response.data;

      commit("ADD_SRT_OFFERS", data);
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response?.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  createSrtOffersImage: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "srt_offers/images/create";
      const response = await Axios.post(url, form, headers);
      const { message, data } = response.data;

      commit("UPDATE_SRT_OFFERS", data);
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response?.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  updateSrtOffer: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );

    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "srt_offers/update";
      const response = await Axios.post(url, form, headers);
      const { message, data } = response.data;

      commit("UPDATE_SRT_OFFERS", data);
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response?.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  deleteSrtOffer: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "srt_offers/delete?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }

      const response = await Axios.delete(url, headers);
      const { message } = response.data;

      commit("DELETE_SRT_OFFER", params.id);
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  deleteSrtOfferImage: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "srt_offers/images/delete?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }

      const response = await Axios.delete(url, headers);
      const { message } = response.data;

      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchSavings: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "admin/savings?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_SAVINGS", { data, last_page, current_page: params.page });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchSavingsPaginated: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "admin/savings?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_SAVINGS_PAGINATED", {
        data,
        last_page,
        current_page: params.page,
      });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  updateInvestmentStatus: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "investments/status";
      const response = await Axios.post(url, form, headers);
      const { message, data } = response.data;

      commit("UPDATE_INVESTMENT", data);
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchSavingsPaginated: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "admin/savings?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_SAVINGS_PAGINATED", {
        data,
        last_page,
        current_page: params.page,
      });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message ?? "";
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  fetchGoalWithdrawals: async ({ commit, rootState }, params) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "goals/withdrawal/view?";
      for (let key in params) {
        if (params[key]) {
          url += `&${key}=${params[key]}`;
        }
      }
      const response = await Axios.get(url, headers);
      const { data, last_page } = response.data;

      commit("SET_GOAL_WITHDRAWALS", {
        data,
        last_page,
        current_page: params.page,
      });
      commit(
        "SET_LOADING",
        { loading: false, message: "", error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  createGoal: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "goals/create";
      const response = await Axios.post(url, form, headers);
      const { message, data } = response.data;

      commit("ADD_GOAL", data);
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  createGoalWithdrawals: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "goals/withdrawal/create";
      const response = await Axios.post(url, form, headers);
      const { message, data } = response.data;

      commit("ADD_GOAL_WITHDRAWAL", data);
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
  adjustGoalAmount: async ({ commit, rootState }, form) => {
    commit(
      "SET_LOADING",
      { loading: true, message: "", error: false },
      { root: true }
    );
    try {
      let ip_address = rootState.auth.ip_address;
      let token = rootState.auth.auth_token;
      const headers = tokenHeaders({ ip_address, token });

      let url = ADMIN_URL + "goals/adjust";
      const response = await Axios.post(url, form, headers);
      const { message, data } = response.data;

      commit("SET_GOAL_DETIALS", data);
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: false, created: true },
        { root: true }
      );
    } catch (error) {
      const message = error.response.data?.message;
      commit(
        "SET_LOADING",
        { loading: false, message: message, error: true },
        { root: true }
      );
    }
  },
};

const mutations = {
  SET_GOALS: (state, newValue) => {
    state.goals = newValue.data;
    state.goals_page = newValue.current_page;
    state.goals_last_page = newValue.last_page;
  },
  SET_GOALS_PAGINATED: (state, newValue) => {
    state.goals = [...state.goals, ...newValue.data];
    state.goals_page = newValue.current_page;
    state.goals_last_page = newValue.last_page;
  },
  SET_GOAL_DETIALS: (state, newValue) => {
    state.goal_details = newValue;
  },
  ADD_GOAL: (state, newValue) => {
    state.goals = [newValue, ...state.goals];
  },
  UPDATE_GOALS: (state, newValue) => {
    let newArray = state.goals.map((item) => {
      if (item.id == newValue.id) item = newValue;

      return item;
    });

    state.goals = newArray;
  },
  SET_GOAL_TRANSACTIONS: (state, newValue) => {
    state.goal_transactions = newValue.data;
    state.goal_transactions_page = newValue.current_page;
    state.goal_transactions_last_page = newValue.last_page;
  },
  SET_GOAL_WITHDRAWALS: (state, newValue) => {
    state.goal_withdrawals = newValue.data;
    state.goal_withdrawal_page = newValue.current_page;
    state.goal_withdrawal_last_page = newValue.last_page;
  },
  ADD_GOAL_WITHDRAWAL: (state, newValue) => {
    state.goal_withdrawals = [newValue, ...state.goal_withdrawals];
  },
  SET_INVESTMENTS: (state, newValue) => {
    state.investments = newValue.data;
    state.investment_page = newValue.current_page;
    state.investment_last_page = newValue.last_page;
  },
  SET_VIEW_INVESTMENT: (state, newValue) => {
    state.view_investment = newValue;
  },
  SET_INVESTMENTS_PAGINATED: (state, newValue) => {
    (state.investments = [...state.investments, newValue.data]),
      (state.investments_page = newValue.current_page);
    state.investments_last_page = newValue.last_page;
  },
  SET_INVESTMENTS_CLASS: (state, newValue) => {
    state.investment_class = newValue.data;
    state.investment_class_page = newValue.current_page;
    state.investment_class_last_page = newValue.last_page;
  },
  SET_INVESTMENTS_CLASS_PAGINATED: (state, newValue) => {
    state.investment_class = [...state.investment_class, ...newValue.data];
    state.investment_class_page = newValue.current_page(
      (state.investment_class_last_page = newValue.last_page)
    );
  },
  SET_INVESTMENTS_SURVEY: (state, newValue) => {
    state.investment_survey = newValue.data;
    state.investment_survey_page = newValue.current_page;
    state.investment_survey_last_page = newValue.last_page;
  },
  SET_INVESTMENTS_SURVEY_PAGINATED: (state, newValue) => {
    state.investment_survey = [...state.investment_survey, ...newValue.data];
    state.investment_survey_page = newValue.current_page;
    state.investment_survey_last_page = newValue.last_page;
  },
  ADD_INVESTMENT_SURVEY: (state, newValue) => {
    state.investment_survey = [newValue, ...state.data];
  },
  UPDATE_INVESTMENT_SURVEY: (state, newValue) => {
    let newArray = state.investment_survey.map((item) => {
      if (item.id == newValue.id) item = newValue;
      return item;
    });

    state.investment_survey = newArray;
  },
  DELETE_INVESTMENT_SURVEY: (state, newValue) => {
    let newArray = state.investment_survey.filter(
      (item) => item.id != newValue
    );
    state.investment_survey = newArray;
  },
  SET_SRT_OFFERS: (state, newValue) => {
    state.srt_offers = newValue.data;
    state.srt_offers_page = newValue.current_page;
    state.srt_offers_last_page = newValue.last_page;
  },
  SET_SRT_OFFER: (state, newValue) => {
    state.srt_offer = newValue.data;
  },
  ADD_SRT_OFFERS: (state, newValue) => {
    state.srt_offers = [newValue, ...state.srt_offers];
  },
  UPDATE_SRT_OFFERS: (state, newValue) => {
    let newArray = state.srt_offers.map((item) => {
      if (item.id == newValue.id) item = newValue;
      return item;
    });

    state.srt_offers = newArray;
  },
  DELETE_SRT_OFFER: (state, newValue) => {
    let newArray = state.srt_offers.filter((item) => item.id != newValue);
    state.srt_offers = newArray;
  },
  SET_SAVINGS: (state, newValue) => {
    state.savings = newValue.data;
    state.savings_page = newValue.current_page;
    state.savings_last_page = newValue.last_page;
  },
  SET_SAVINGS_PAGINATED: (state, newValue) => {
    (state.savings = [...state.savings, newValue.data]),
      (state.savings_page = newValue.current_page);
    state.savings_last_page = newValue.last_page;
  },
  UPDATE_INVESTMENT: (state, newValue) => {
    let new_array = state.investments.map((item) => {
      if (item.id == newValue.id) {
        item = newValue;
      }
      return item;
    });

    state.investments = new_array;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
